import React from 'react'
import { Link } from 'react-router-dom'

const AboutusAbout = () => {
    return (
        <section className="p-top-90 p-bottom-90 bg-gray-gradient">
            <div className="container">
                <div className="row g-0">
                    <div className="col-12 col-xl-6 order-1 order-xl-0">
                        {/* <!-- Image --> */}
                        <div className="image-info image-info-vertical me-xl-5">
                            <div className="vertical-title">
                                <small className="ls-2">
                                    <strong className="text-primary fw-semibold">Sine 1993</strong> - <strong className="text-body fw-semibold">31 years</strong> of experience
                                </small>
                            </div>
                            <div className="image-center rounded">
                                <img src="./assets/img/about/tripnow-about.avif" srcset="./assets/img/about/tripnow-about.avif 2x" className="w-100" alt="" />
                            </div>
                            <div className="position-absolute top-0 end-0 me-4 mt-4">
                                <div className="vertical-award rounded shadow-sm">
                                    <div className="award-content">
                                        <img src="./assets/img/logos/trip-best.png" srcset="./assets/img/logos/trip-best@2x.png 2x" className="w-100" alt="" />
                                    </div>
                                    <div className="award-footer">
                                        <small>Tripadvisor</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- /Image --> */}
                    </div>
                    <div className="col-12 col-xl-6 order-0 order-xl-1">
                        {/* <!-- Content --> */}
                        <div className="pt-xl-4 mb-xl-0 mb-5">
                            <div className="block-title">
                                <small className="sub-title">Tripnow</small>
                                <h2 className="h1 title lh-sm">About Us - TripNow</h2>
                            </div>
                            <p>
                                Welcome to TripNow, where your journey to discovering the heart and soul of India begins. Nestled in the sacred city of Varanasi, we are more than just a travel agency – we’re storytellers, curators of unforgettable memories, and your companions in exploring the timeless beauty of India.
                            </p>
                            <p>
                                Our passion lies in helping you experience the spiritual and cultural richness of this incredible land. From the serene ghats of Varanasi to the historic temples of Ayodhya, the spiritual vibe of Prayagraj, the peaceful retreats of Bodhgaya, and the scenic beauty of Chitrakoot, we’re here to make every moment of your journey meaningful and magical.
                            </p>
                            <div className="pt-3">
                                <Link to="/contact-us" className="btn btn-primary mnw-180">
                                    <i className="hicon hicon-email-envelope"></i>
                                    <span> Let's talk now</span>
                                </Link>
                                <a href="tel:+919236975458" className="btn btn-link link-dark link-hover fw-medium mnw-180">
                                    <i className="hicon hicon-telephone"></i>
                                    <span> +91 9236975458</span>
                                </a>
                            </div>
                        </div>
                        {/* <!-- /Content --> */}
                    </div>
                </div>
            </div>
        </section>)
}

export default AboutusAbout