import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { addToOrders, setUserData } from '../redux/userSlice';

const Singlebooktour = ({ product }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const loggedIn = useSelector((state) => state.user.isLoggedIn);

    const [formData, setFormData] = useState({
        name: '',
        contactNumber: '',
        people: 1, // Renamed from adults to people
        productId: product._id,
    });
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const userData = useSelector((state) => state.user.userData);
    const [loading, setLoading] = useState(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: name === "people" ? Number(value) : value, // Convert to number if it's people
        }));
    };

    const handleBookTour = async (e) => {
        if (!loggedIn) {
            navigate("/login");
            return;
        }
        e.preventDefault();
        setError('');
        setSuccess('');
        setLoading(true); // Start loading

        try {
            if (userData.orders.some(order => order.product === formData.productId)) {
                setError("You have already booked this tour.");
            } else {
                const response = await axios.post('/user/api/bookingtours', formData, {
                    validateStatus: function (status) {
                        return status >= 200 && status < 400;
                    }
                });

                setSuccess('Tour booked successfully! Proceed to checkout.');
                setError('');
                navigate('/thank-you');

                const userdata = response.data?.userData;
                if (userdata) {
                    dispatch(setUserData({
                        ...userdata,
                        isLoggedIn: true,
                    }));
                } else {
                    dispatch(addToOrders(formData));
                }
            }
        } catch (error) {
            console.error('Full error object:', error);
            console.error('Error details:', error.response?.data);

            if (error.response) {
                setError(error.response.data.message || 'Failed to book the tour. Please try again.');
            } else if (error.request) {
                setError('No response from server. Please try again later.');
            } else {
                setError(`Error in booking tour. kuchh to error hai ${error}.`);
            }
            setSuccess('');
        } finally {
            setLoading(false); // Stop loading after request completes
        }
    };

    useEffect(() => { }, [userData]);

    return (
        <aside className="sticky-top sticky-top-120 ps-xl-4 ms-xl-2 ps-xxl-0 ms-xxl-0">
            <div className="card border-0 shadow-sm">
                <div className="card-body">
                    <div className="border-bottom pb-4 mb-4">
                        <span>From:</span>
                        <strong className="fs-2 fw-semibold fw-medium"><sup>₹</sup>{product.price}</strong>
                        <span className="text-uppercase text-secondary"><sup>₹</sup><del className="">{product.price + 1200}</del></span>
                    </div>
                    {error && <div className="alert alert-danger">{error}</div>}
                    {success && <div className="alert alert-success">{success}</div>}
                    <div className="border-bottom pb-4 mb-4">
                        <form className="row g-3" onSubmit={handleBookTour}>
                            <div className="col-12">
                                <label htmlFor="name" className='d-none'>Name</label>
                                <input
                                    id="name"
                                    name="name"
                                    type="text"
                                    className="form-control shadow-sm"
                                    placeholder="Your Name"
                                    value={formData.name}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                            <div className="col-12" >
                                <label htmlFor="contactNumber" className='d-none'>Contact Number</label>
                                <input
                                    id="contactNumber"
                                    name="contactNumber"
                                    type="tel"
                                    className="form-control shadow-sm"
                                    placeholder="Your Contact Number"
                                    value={formData.contactNumber}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                            <div className="col-12 col-xl-12 col-md-6">
                                <div className="input-icon-group">
                                    <label className="input-icon hicon hicon-adults-line hicon-bold" htmlFor="people"></label>
                                    <select
                                        className="form-select dropdown-select shadow-sm"
                                        id="people"
                                        name="people"
                                        value={formData.people}
                                        onChange={handleInputChange}
                                    >
                                        {Array.from({ length: 10 }, (_, i) => (
                                            <option key={i + 1} value={i + 1}>{i + 1} person{(i > 0 ? 's' : '')}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="col-12">
                                <button type="submit" className="btn btn-primary w-100 fw-medium" disabled={loading}>
                                    {loading ? "Booking..." : (
                                        <>
                                            <i className="hicon hicon-mmb-my-booking hicon-md mr-1"></i>
                                            <span> Book now</span>
                                        </>
                                    )}
                                </button>
                            </div>
                        </form>
                    </div>
                    <div className="mt-4 fw-normal">
                        <h3 className="h4">You have questions?</h3>
                        <div className="row g-3">
                            <div className="col-12 col-xl-12 col-md-6">
                                <Link to="mailto:tripnow.cc@gmail.com" className="link-dark link-hover">
                                    <i className="hicon hicon-bold hicon-email-envelope me-1"></i>
                                    <span>tripnow.cc@gmail.com</span>
                                </Link>
                            </div>
                            <div className="col-12 col-xl-12 col-md-6">
                                <Link to="tel:+919236975458" className="link-dark link-hover">
                                    <i className="hicon hicon-bold hicon-telephone me-1"></i>
                                    <span>+91 9236975458</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </aside>
    );
};

export default Singlebooktour;
