import React from 'react'
import { Link } from 'react-router-dom'

const About = () => {
    return (
        <section className="p-top-90 p-bottom-90 bg-gray-gradient" >
            <div className="container">
                <div className="row g-0">
                    <div className="col-12 col-xl-6 order-1 order-xl-0">
                        <div className="pe-xl-5">
                            <div className="image-info image-info-right image-info-vertical">
                                <div className="vertical-title">
                                    <small className="ls-2">
                                        <strong className="text-primary fw-semibold">Since 1993</strong> - <strong className="text-body fw-semibold">31 years</strong> of experience
                                    </small>
                                </div>
                                <div className="image-center">
                                    <img src="assets/img/about/tripnow-about.avif" srcSet="assets/img/about/tripnow-about.avif 2x" className="rounded w-100" alt="" />
                                </div>
                                <div className="info-top-right">
                                    <div className="vertical-award rounded shadow-sm">
                                        <div className="award-content">
                                            <img src="assets/img/logos/trip-best.png" srcSet="assets/img/logos/trip-best@2x.png 2x" className="w-100" alt="" />
                                        </div>
                                        <div className="award-footer">
                                            <small>Tripadvisor</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-xl-6 order-0 order-xl-1">
                        <div className="mb-5 mb-xl-0">
                            <div className="block-title">
                                <small className="sub-title">About us</small>
                                <h2 className="h1 title">Tripnow Travel Agency</h2>
                            </div>
                            <p>
                                TripNow, based in the heart of Varanasi, brings India’s rich heritage and spirituality closer to you. We specialize in curated tours to sacred and historic destinations like Varanasi, Prayagraj, Ayodhya, Chitrakoot, and Bodhgaya.
                            </p>
                            <p>
                                From serene Ganga boat rides to exploring ancient temples, our tours are crafted to inspire and connect. With personalized service and a passion for meaningful travel, TripNow ensures every journey is unforgettable.
                            </p>
                            <strong>Discover India’s timeless treasures with TripNow – where every trip tells a story!
                            </strong>

                            <div className="pt-3">
                                <Link to="/about-us" className="btn btn-primary mnw-180">
                                    <span>Read more</span>
                                    <i className="hicon hicon-flights-one-ways"></i>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About