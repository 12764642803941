import React from 'react';
import { Link } from 'react-router-dom';

const TermandCondition = () => {
    return (
        <section className="p-top-90 p-bottom-90 bg-gray-gradient">
            <div className="container">
                <div className="mb-4">
                    <div className="row g-0 g-md-2 align-items-center">
                        <div className="col-12 col-xl-8">
                            {/* Title */}
                            <div className="block-title me-auto">
                                <h2 className="h1 title">Terms of Use</h2>
                                <small className="sub-title">Effective Date: 20-11-2024</small>
                            </div>
                            <div className="col-12">
                                <h3 className="h3 title">Introduction</h3>
                                <p>
                                    Welcome to <Link to="/"><span style={{ color: 'blue' }}>TripNow</span></Link>! These Terms of Use ("Terms") govern your use of our website <Link to="/"><span style={{ color: 'blue' }}>(tripnow.cc)</span></Link> and our services. By accessing or using our website and services, you agree to comply with and be bound by these Terms.
                                </p>
                            </div>
                            <div className="col-12">
                                <h3 className="h3 title">1. Use of Our Services</h3>
                                <p><span style={{ fontWeight: '500', opacity: '0.9' }}>1. Eligibility: </span>Our services are intended for individuals aged 18 and above. Minors must be supervised by a guardian.</p>
                                <p><span style={{ fontWeight: '500', opacity: '0.9' }}>2. Lawful Use: </span>You agree to use the website only for lawful purposes and refrain from any activity that could harm the website's integrity.</p>
                            </div>
                            <div className="col-12">
                                <h3 className="h3 title">2. Booking Policies</h3>
                                <p><span style={{ fontWeight: '500', opacity: '0.9' }}>1. Booking Confirmation: </span>Bookings are confirmed only after receipt of payment.</p>
                                <p><span style={{ fontWeight: '500', opacity: '0.9' }}>2. Cancellations & Refunds: </span>Cancellations are subject to our <Link to="/cancellation-policy">cancellation policy</Link>, with refunds processed within 7-10 days.</p>
                                <p><span style={{ fontWeight: '500', opacity: '0.9' }}>3. Modifications: </span>Any changes to bookings are subject to availability and additional charges.</p>
                            </div>
                            <div className="col-12">
                                <h3 className="h3 title">3. Intellectual Property</h3>
                                <p><span style={{ fontWeight: '500', opacity: '0.9' }}>Ownership: </span>All content, logos, and trademarks on the website are the property of TripNow. Unauthorized use is prohibited.</p>
                            </div>
                            <div className="col-12">
                                <h3 className="h3 title">4. Limitation of Liability</h3>
                                <p>TripNow is not responsible for losses due to unforeseen events, such as natural disasters or third-party failures. Travelers are responsible for adhering to local laws and customs during tours.</p>
                            </div>
                            <div className="col-12">
                                <h3 className="h3 title">5. Governing Law</h3>
                                <p>These terms are governed by the laws of India, with jurisdiction in Varanasi, Uttar Pradesh.</p>
                            </div>
                            <div className="col-12">
                                <h3 className="h3 title">6. Termination</h3>
                                <p>We reserve the right to terminate user access for violations of these terms.</p>
                            </div>
                            <div className="col-12">
                                <h3 className="h3 title">7. Contact Us</h3>
                                <p>If you have any questions about these Terms, please contact us at:</p>
                                <p style={{ fontWeight: 'bold' }}>TripNow</p>
                                <div className="contact-info">
                                    <p><span>1063 Maruti Nagar Colony, Varanasi, India - 221011</span></p>
                                    <p><span>+91 9236975458</span></p>
                                    <p><Link to="mailto:tripnow.cc@gmail.com">tripnow.cc@gmail.com</Link></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default TermandCondition;
