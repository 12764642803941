import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { setUserData } from '../redux/adminSlice';

function AdminLogin() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const isAdminLoggedIn = useSelector((state) => state.admin.isAdminLoggedIn);
    console.log(isAdminLoggedIn);


    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('/admin/api/login', { email, password });
            if (response.status === 200) {
                const adminResponse = await axios.get("/admin/api/adminsdata");
                const adminData = adminResponse.data;

                dispatch(setUserData({
                    ...adminData,
                    isLoggedIn: true, // Ensure this flag is part of the dispatched data
                })); navigate('/adminpanel');
            }
        } catch (err) {
            console.error("Login Error:", err.response ? err.response.data : err.message);
            if (err.response && err.response.status === 401) {
                setError('Admin does not exist.');
            } else if (err.response && err.response.status === 402) {
                setError('Email or password is incorrect.');
            } else {
                setError('An error occurred during login. Please try again.');
            }
        }
    };

    return (
        <main>
            <section className="p-top-90 p-bottom-90 bg-light-gray-gradient">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-xl-5 col-lg-8 col-md-9">
                            <div className="card border-0 shadow-sm p-xl-2">
                                <div className="card-body">
                                    <div className="border-bottom mb-4">
                                        <h1 className="h2 text-body-emphasis">Login</h1>
                                    </div>
                                    {error && <div className="alert alert-danger">{error}</div>}
                                    <form onSubmit={handleSubmit}>
                                        {/* Login with Facebook/Google */}

                                        {/* Login with Email */}
                                        <div className="border-bottom pb-4">
                                            <div className="mb-4">
                                                <label htmlFor="txtEmail" className="form-label">Email<span className="text-danger">*</span></label>
                                                <input
                                                    className="form-control shadow-sm"
                                                    type="text"
                                                    id="txtEmail"
                                                    name="username"
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    required
                                                />
                                            </div>
                                            <div className="mb-4">
                                                <label htmlFor="txtPassword" className="form-label">Password<span className="text-danger">*</span></label>
                                                <input
                                                    className="form-control shadow-sm"
                                                    id="txtPassword"
                                                    name="password"
                                                    type="password"
                                                    value={password}
                                                    onChange={(e) => setPassword(e.target.value)}
                                                    required
                                                />
                                            </div>
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="mb-4">
                                                        <div className="form-check">
                                                            <input className="form-check-input shadow-sm" type="checkbox" id="chkRemember" />
                                                            <label className="form-check-label" htmlFor="chkRemember"><span className="text-body">Remember me</span></label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="mb-4 text-end">
                                                        <Link to="/forgot-password">Forgot password?</Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <button type="submit" className="btn btn-primary d-block w-100">Login</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="text-center mt-4">
                                <p className="text-muted">Don't have an account? <Link to="/adminregister">Sign up</Link></p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}

export default AdminLogin;
