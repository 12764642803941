import React from 'react'

const Categories = () => {
    return (
        <section className="p-top-90 p-bottom-90 bg-gray-gradient">
            <div className="container">
                {/* <!-- Title --> */}
                <div className="d-xl-flex align-items-xl-center pb-4">
                    <div className="block-title me-auto">
                        <small className="sub-title">Tour categories</small>
                        <h2 className="h1 title">Adventure types</h2>
                    </div>

                </div>
                {/* <!-- /Title --> */}
                {/* <!-- Category list --> */}
                <div className="row g-3">
                    <div className="col-12 col-xxl-3 col-xl-4 col-md-6">
                        <div className="mini-card card-hover hover-effect shadow-sm rounded">
                            <span className="card-icon">
                                <i className="hicon hicon-regular-valley"></i>
                            </span>
                            <div className="card-content">
                                <h3 className="h5 card-title">Meditation and Mindfulness Retreats</h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-xxl-3 col-xl-4 col-md-6">
                        <div className="mini-card card-hover hover-effect shadow-sm rounded">
                            <div className="d-flex align-items-center">
                                <span className="card-icon">
                                    <i className="hicon hicon-regular-meal"></i>
                                </span>
                                <div className="card-content">
                                    <h3 className="h5 card-title">Sacred Ganga Aarti Experience
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-xxl-3 col-xl-4 col-md-6">
                        <div className="mini-card card-hover hover-effect shadow-sm rounded">
                            <div className="d-flex align-items-center">
                                <span className="card-icon">
                                    <i className="hicon hicon-culture"></i>
                                </span>
                                <div className="card-content">
                                    <h3 className="h5 card-title">Temples of Light and Wisdom</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-xxl-3 col-xl-4 col-md-6">
                        <div className="mini-card card-hover hover-effect shadow-sm rounded">
                            <div className="d-flex align-items-center">
                                <span className="card-icon">
                                    <i className="hicon hicon-mountain-view"></i>
                                </span>
                                <div className="card-content">
                                    <h3 className="h5 card-title">Spiritual Heritage Walks
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-xxl-3 col-xl-4 col-md-6">
                        <div className="mini-card card-hover hover-effect shadow-sm rounded">
                            <div className="d-flex align-items-center">
                                <span className="card-icon">
                                    <i className="hicon hicon-china-only"></i>
                                </span>
                                <div className="card-content">
                                    <h3 className="h5 card-title">The Kashi Circuit
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-xxl-3 col-xl-4 col-md-6">
                        <div className="mini-card card-hover hover-effect shadow-sm rounded">
                            <div className="d-flex align-items-center">
                                <span className="card-icon">
                                    <i className="hicon hicon-regular-mountain-view"></i>
                                </span>
                                <div className="card-content">
                                    <h3 className="h5 card-title">The Ramayana Trail</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-xxl-3 col-xl-4 col-md-6">
                        <div className="mini-card card-hover hover-effect shadow-sm rounded">
                            <div className="d-flex align-items-center">
                                <span className="card-icon">
                                    <i className="hicon hicon-regular-hiking"></i>
                                </span>
                                <div className="card-content">
                                    <h3 className="h5 card-title">The Enlightenment Journey</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-xxl-3 col-xl-4 col-md-6">
                        <div className="mini-card card-hover hover-effect shadow-sm rounded">
                            <div className="d-flex align-items-center">
                                <span className="card-icon">
                                    <i className="hicon hicon-bicycle-rental"></i>
                                </span>
                                <div className="card-content">
                                    <h3 className="h5 card-title">The Sangam Spiritual Pilgrimage
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-xxl-3 col-xl-4 col-md-6">
                        <div className="mini-card card-hover hover-effect shadow-sm rounded">
                            <div className="d-flex align-items-center">
                                <span className="card-icon">
                                    <i className="hicon hicon-regular-yachting"></i>
                                </span>
                                <div className="card-content">
                                    <h3 className="h5 card-title">Boating Expeditions
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-xxl-3 col-xl-4 col-md-6">
                        <div className="mini-card card-hover hover-effect shadow-sm rounded">
                            <div className="d-flex align-items-center">
                                <span className="card-icon">
                                    <i className="hicon hicon-family-with-teens"></i>
                                </span>
                                <div className="card-content">
                                    <h3 className="h5 card-title">Cycling through History
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-xxl-3 col-xl-4 col-md-6">
                        <div className="mini-card card-hover hover-effect shadow-sm rounded">
                            <div className="d-flex align-items-center">
                                <span className="card-icon">
                                    <i className="hicon hicon-massage"></i>
                                </span>
                                <div className="card-content">
                                    <h3 className="h5 card-title">Trekking through Chitrakoot</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-xxl-3 col-xl-4 col-md-6">
                        <div className="mini-card card-hover hover-effect shadow-sm rounded">
                            <div className="d-flex align-items-center">
                                <span className="card-icon">
                                    <i className="hicon hicon-library"></i>
                                </span>
                                <div className="card-content">
                                    <h3 className="h5 card-title">Sunrise Photography
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- /Category list --> */}
            </div>
        </section>
    )
}

export default Categories