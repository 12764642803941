import React from 'react'

const Singletourdetails = ({ product }) => {
    console.log(product.whychooseus);

    const getImageSrc = (picture) => {
        if (!picture) {
            return '../../assets/img/avatars/a3@2x.jpg';
        }
        return picture.startsWith('http') ? picture : picture;
    };

    return (
        <>
            <div className="card border-0 shadow-sm p-xl-2 mb-4" >
                <div className="card-body">
                    <div className="border-bottom mb-4 pb-4">
                        <h2 className="text-body-emphasis mb-0">About</h2>
                    </div>
                    <div className="d-lg-flex border-bottom pb-3 mb-4">
                        <div className="mnw-200">
                            <h3 className="h5">Highlights</h3>
                        </div>
                        <ul className="highlight-list checked">
                            {
                                product.about.highlight.map((data) => (
                                    <li>
                                        <span><strong>{data.title}:</strong> {data.highlightDescription}</span>
                                    </li>
                                ))
                            }

                        </ul>
                    </div>
                    <div className="d-lg-flex">
                        <div className="mnw-200">
                            <h3 className="h5">Description</h3>
                        </div>
                        <p>
                            {product.about.description}
                        </p>
                    </div>
                </div>
            </div>
            {/* <!-- /About --> */}
            {/* <!-- Photos --> */}
            <div className="card border-0 shadow-sm p-xl-2 mb-4" >
                <div className="card-body">
                    <div className="border-bottom mb-4 pb-4">
                        <h2 className="text-body-emphasis mb-0">
                            Photos
                        </h2>
                    </div>
                    <div className="row g-1 align-items-center">
                        <div className="col-12 col-xl-6">
                            <figure className="image-hover image-hover-scale image-hover-overlay rounded mb-0">
                                <img
                                    src={`/${getImageSrc(product.image[1].imageAddress)}`}
                                    alt={product.name}
                                    height={338}
                                    width={451}
                                />
                            </figure>
                        </div>
                        <div className="col-12 col-xl-6">
                            <div className="row g-1 align-items-center">
                                <div className="col-6">
                                    <figure className="image-hover image-hover-scale image-hover-overlay rounded mb-0">
                                        <img
                                            src={`/${getImageSrc(product.image[2].imageAddress)}`}
                                            alt={product.name}
                                            height={167}
                                            width={223}
                                        />
                                    </figure>
                                </div>
                                <div className="col-6">
                                    <figure className="image-hover image-hover-scale image-hover-overlay rounded mb-0">
                                        <img
                                            src={`/${getImageSrc(product.image[3].imageAddress)}`}
                                            alt={product.name}
                                            height={167}
                                            width={223}
                                        />
                                    </figure>
                                </div>
                                <div className="col-6">

                                    <figure className="image-hover image-hover-scale image-hover-overlay rounded mb-0">
                                        <img
                                            src={`/${getImageSrc(product.image[4].imageAddress)}`}
                                            alt={product.name}
                                            height={167}
                                            width={223}
                                        />
                                    </figure>

                                </div>
                                <div className="col-6">

                                    <figure className="image-hover image-hover-scale image-hover-overlay rounded mb-0">
                                        <img
                                            src={`/${getImageSrc(product.image[5].imageAddress)}`}
                                            alt={product.name}
                                            height={167}
                                            width={223}
                                        />
                                    </figure>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-xl-3">

                            <figure className="image-hover image-hover-scale image-hover-overlay rounded mb-0">
                                <img
                                    src={`/${getImageSrc(product.image[6].imageAddress)}`}
                                    alt={product.name}
                                    height={167}
                                    width={223}
                                />
                            </figure>

                        </div>
                        <div className="col-6 col-xl-3">

                            <figure className="image-hover image-hover-scale image-hover-overlay rounded mb-0">
                                <img
                                    src={`/${getImageSrc(product.image[7].imageAddress)}`}
                                    alt={product.name}
                                    height={167}
                                    width={223}
                                />
                            </figure>

                        </div>
                        <div className="col-12 col-xl-6">

                            <figure className="image-hover image-hover-scale image-hover-overlay rounded mb-0">
                                <img
                                    src={`/${getImageSrc(product.image[8].imageAddress)}`}
                                    alt={product.name}
                                    height={169}
                                    width={451}
                                />
                            </figure>

                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- /Photos --> */}
            {/* <!-- Itinerary --> */}
            <div className="card border-0 shadow-sm p-xl-2 mb-4" >
                <div className="card-body">
                    <div className="border-bottom mb-4 pb-4">
                        <h2 className="text-body-emphasis mb-0">
                            Itinerary
                        </h2>
                    </div>
                    <p>
                        {product.iteracy.iteracyDescription}
                    </p>
                    <div className="accordion accordion-flush accordion-itinerary" id="acdItinerary">
                        {
                            product.iteracy.curriculum.map((data, i) => (
                                <div className="accordion accordion-flush accordion-itinerary" id="acdItinerary">
                                    <div className="accordion-item bg-transparent">
                                        <h2 className="accordion-header">

                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#acdContent1${i + 1}`} aria-expanded="false" aria-controls="acdContent1">
                                                <p className='mb-0'>
                                                    <strong className="rounded-1">Day {i + 1}:</strong>
                                                    <span>{data.title}</span>
                                                </p>
                                            </button>

                                        </h2>
                                        <div id={`acdContent1${i + 1}`} className="accordion-collapse collapse" data-bs-parent="#acdItinerary">
                                            <div className="accordion-body">
                                                {data.curriculumDescription}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
            {/* <!-- /Itinerary --> */}
            {/* <!-- Policy --> */}
            <div className="card border-0 shadow-sm p-xl-2 mb-4" >
                <div className="card-body">
                    <div className="border-bottom mb-4 pb-4">
                        <h2 className="text-body-emphasis mb-0">
                            Policy
                        </h2>
                    </div>
                    <div className="d-lg-flex border-bottom pb-3 mb-4">
                        <div className="mnw-200">
                            <h3 className="h5">Price included</h3>
                        </div>
                        <div>
                            <ul className="highlight-list checked">
                                {
                                    product.policy.priceIncluded.map((data) => (
                                        data.checked &&
                                        <li>
                                            <span><strong>{data.title}:</strong> {data.description}</span>
                                        </li>
                                    ))
                                }
                            </ul>
                            <ul className="highlight-list unchecked">
                                {
                                    product.policy.priceIncluded.map((data) => (
                                        !data.checked &&
                                        <li>
                                            <span><strong>{data.title}:</strong> {data.description}</span>
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                    </div>
                    <div className="d-lg-flex border-bottom pb-3 mb-4">
                        <div className="mnw-200">
                            <h3 className="h5">For children</h3>
                        </div>
                        <ul className="highlight-list checked">
                            {
                                product.policy.forChildren.map((data) => (
                                    data.checked &&
                                    <li>
                                        <span><strong>{data.title}:</strong> {data.description}</span>
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                    <div className="d-lg-flex">
                        <div className="mnw-200">
                            <h3 className="h5">Other terms</h3>
                        </div>
                        <p>
                            {product.policy.OtherTerms}
                        </p>
                    </div>
                </div>
            </div>
            <div className="card border-0 shadow-sm p-xl-2 mb-4" >
                <div className="card-body">
                    <div className="border-bottom mb-4 pb-4">
                        <h2 className="text-body-emphasis mb-0">
                            Why Choose TripNow?
                        </h2>
                    </div>
                    <div className="d-lg-flex border-bottom pb-3 mb-4">

                        <div>
                            <ul className="highlight-list checked">

                                {product.whychooseus.map((item) => (
                                    <li key={item.title}>
                                        <span><strong>{item.title}</strong> {item.description}</span>
                                    </li>
                                ))}


                            </ul>

                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default Singletourdetails