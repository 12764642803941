import React from 'react'
import { Link } from 'react-router-dom'


const CancelationPolicy = () => {
    return (
        <section className="p-top-90 p-bottom-90 bg-gray-gradient">
            <div className="container">
                <div className="mb-4">
                    <div className="row g-0 g-md-2 align-items-center">
                        <div className="col-12 col-xl-8">
                            {/* Title */}
                            <div className="block-title me-auto">
                                <h2 className="h1 title">Cancellation and Refund Policy</h2>
                                <small className="sub-title">Effective Date: 20-11-2024</small>
                            </div>
                            <div className="col-12">
                                <h3 className="h3 title">Introduction</h3>
                                <p>
                                    At TripNow, we understand that travel plans can change unexpectedly. Our cancellation and refund policy is designed to be fair and transparent, ensuring a smooth experience for both our clients and our service providers.
                                </p>
                            </div>
                            <div className="col-12">
                                <h3 className="h3 title">Cancellation Policy</h3>
                                <h4>Cancellation Deadline:</h4>
                                <p><span style={{ fontWeight: '500', opacity: '0.9' }}>30+ Days Before Departure: </span>90% refund of the total booking amount.</p>
                                <p><span style={{ fontWeight: '500', opacity: '0.9' }}>15–30 Days Before Departure: </span>75% refund of the total booking amount.</p>
                                <p><span style={{ fontWeight: '500', opacity: '0.9' }}>7–14 Days Before Departure: </span>50% refund of the total booking amount.</p>
                                <p><span style={{ fontWeight: '500', opacity: '0.9' }}>Less Than 7 Days Before Departure: </span>No refund will be provided.</p>
                            </div>
                            <div className="col-12">
                                <h4>Force Majeure:</h4>
                                <p>In cases of extreme weather conditions, natural disasters, or other unforeseen events (force majeure), full refunds will be provided, regardless of the cancellation timeframe.</p>
                            </div>
                            <div className="col-12">
                                <h4>No-Show Policy:</h4>
                                <p>No refunds will be issued for missed tours or trips due to personal reasons, such as late arrival, illness, or missed flights.</p>
                            </div>
                            <div className="col-12">
                                <h3 className="h3 title">Refund Policy</h3>
                                <p>Refunds for eligible cancellations will be processed within 7–10 business days from the date of cancellation.</p>
                                <p>Refunds will be issued to the original payment method used at the time of booking.</p>
                                <p>Additional bank processing charges, if applicable, will be deducted from the refund amount.</p>
                            </div>
                            <div className="col-12">
                                <h3 className="h3 title">Special Cases</h3>
                                <h4>Festive Season Bookings:</h4>
                                <p>Payments made for bookings during peak seasons (e.g., December 20 to January 5) are non-refundable due to high demand and advance commitments to our vendors.</p>
                                <h4>Unused Services:</h4>
                                <p>Refunds will not be provided for unused services such as accommodations, transport, or meals.</p>
                            </div>
                            <div className="col-12">
                                <h3 className="h3 title">How to Cancel a Booking</h3>
                                <p>To cancel your booking:</p>
                                <ul>
                                    <li>Email us at <Link to="mailto:tripnow.cc@gmail.com"><span style={{ color: 'blue' }}>tripnow.cc@gmail.com</span></Link> with your booking reference number and a brief reason for cancellation.</li>
                                    <li>Once we receive your request, we will initiate the cancellation process and confirm via email.</li>
                                </ul>
                            </div>
                            <div className="col-12">
                                <h3 className="h3 title">Important Notes</h3>
                                <ul>
                                    <li>Cancellations made on non-working days (e.g., Sundays or public holidays) will be considered on the next working day.</li>
                                    <li>TripNow reserves the right to modify or update this policy as per business requirements. Please refer to the latest policy on our website for current information.</li>
                                </ul>
                            </div>
                            <div className="col-12">
                                <h3 className="h3 title">Contact Us</h3>
                                <p>For further questions or assistance, feel free to contact us at:</p>
                                <p style={{ fontWeight: 'bold' }}>TripNow</p>
                                <div className="contact-info">
                                    <p><span>1063 Maruti Nagar Colony, Varanasi, India - 221011</span></p>
                                    <p><span>+91 9236975458</span></p>
                                    <p><Link to="mailto:tripnow.cc@gmail.com">tripnow.cc@gmail.com</Link></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>)
}

export default CancelationPolicy