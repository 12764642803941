import React from 'react'
import { Link } from 'react-router-dom'

const WhatDriveUS = () => {
    return (
        <section className="p-top-90 p-bottom-90 bg-gray-gradient">
            <div className="container">
                <div className="row g-0">
                    <div className="col-12 col-xl-6 order-1 order-xl-0">
                        {/* <!-- Content --> */}
                        <div className="pt-xl-4 mb-xl-0 mb-5">
                            <div className="block-title">
                                <h2 className="h1 title lh-sm">What Drives Us</h2>
                            </div>
                            <p>
                                At TripNow, we’re not just about helping you visit places – we’re about helping you connect. Connect with history, culture, spirituality, and most importantly, yourself. Whether you’re traveling solo, with family, or with friends, we’ll be there to make every step of your journey smooth, safe, and unforgettable.
                            </p>
                            <p>
                                So, are you ready to create memories that will last a lifetime? Let’s explore India together, one destination at a time.
                            </p>
                            <p>With <Link to="/">TripNow</Link>, your journey isn’t just about reaching the destination – it’s about cherishing every moment along the way.</p>
                            <strong>
                                Let’s make your travel dreams come true.
                            </strong>
                        </div>
                        {/* <!-- /Content --> */}
                    </div>
                    <div className="col-12 col-xl-6 order-0 order-xl-1">
                        {/* <!-- Image --> */}
                        <div className="image-info image-info-vertical me-xl-5">

                            <div className="image-center rounded">
                                <img src="./assets/img/about/tripnow-about.avif" srcset="./assets/img/about/tripnow-about.avif 2x" className="w-100" alt="" />
                            </div>
                            <div className="position-absolute top-0 end-0 me-4 mt-4">
                                <div className="vertical-award rounded shadow-sm">
                                    <div className="award-content">
                                        <img src="./assets/img/logos/trip-best.png" srcset="./assets/img/logos/trip-best@2x.png 2x" className="w-100" alt="" />
                                    </div>
                                    <div className="award-footer">
                                        <small>Tripadvisor</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- /Image --> */}
                    </div>

                </div>
            </div>
        </section>)
}

export default WhatDriveUS