import { configureStore } from '@reduxjs/toolkit';
import userReducer from './userSlice';
import adminReducer from "./adminSlice";
import productReducer from "./productSlice";
import destinationReducer from './destinationSlice';
import hotelReducer from './hotelSclice';  // Corrected the typo from 'hotelSclice' to 'hotelSlice'
import cabReducer from './cabSlice';  // Import the cab slice reducer

const store = configureStore({
    reducer: {
        user: userReducer,
        admin: adminReducer,
        product: productReducer,
        destination: destinationReducer,
        hotels: hotelReducer,
        cabs: cabReducer,  // Added the cab reducer
    },
});

export default store;
