import React, { useEffect } from 'react';
import Singletourhero from '../components/Singletourhero';
import Singletourfeature from '../components/Singletourfeature';
import Singletourdetails from '../components/Singletourdetails';
import Singlebooktour from '../components/Singlebooktour';
import Singletourreview from '../components/Singletourreview';
import { Othertours } from '../components/Othertours';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProducts } from '../redux/productSlice';

const Singletour = () => {
    const { id } = useParams()



    const dispatch = useDispatch();
    const { products, loading, error } = useSelector((state) => state.product);
    useEffect(() => {
        dispatch(fetchProducts());
    }, [dispatch]);

    // console.log("prodcuts", products);

    if (!loading && !error && products.length > 0) {
        const filterproduct = products.find((item) =>
            item.slug.toLowerCase().split("-").join("") === id.toLowerCase().split("-").join("")
        );

        const filterProducts = products.filter((item) =>
            item.slug.toLowerCase().split("-").join("") !== id.toLowerCase().split("-").join("")
        );

        console.log("filterproduct for single page", filterproduct)
        // console.log("filterproducts for single page", filterProducts)

        return (
            <>
                <Singletourhero product={filterproduct} />
                <section className="p-top-90 p-bottom-90 bg-gray-gradient">

                    <div className="container">

                        <div className="row g-0">
                            <div className="col-12 col-xxl-9 col-xl-8">
                                <div className='pe-xxl-4 me-xxl-2'>
                                    <Singletourfeature product={filterproduct} />
                                    <Singletourdetails product={filterproduct} />
                                    <Singletourreview product={filterproduct} />
                                </div>
                            </div>
                            <div className="col-12 col-xxl-3 col-xl-4">
                                <Singlebooktour product={filterproduct} />

                            </div>
                        </div>
                    </div>
                </section>
                <Othertours filterProducts={filterProducts} title="Other Tours" subtitle="Maybe you like " />

            </>
        )
    }
    else {
        return (
            <>
                No data found
            </>
        );
    }



};

export default Singletour;
