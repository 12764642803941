import React from 'react';
import { Link } from 'react-router-dom';

const Destinationtitle = () => {
    return (
        <section className="hero">
            <div className="hero-bg">
                <img
                    src="./assets/img/destinations/tripnow-destinations.avif"
                    srcSet="./assets/img/destinations/tripnow-destinations.avif 2x"
                    alt="Destination"
                />
            </div>
            <div className="bg-content container">
                <div className="hero-page-title">
                    <span className="hero-sub-title">Tripnow tours</span>
                    <h1 className="display-3 hero-title">
                        Destinations
                    </h1>
                </div>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb mb-0">
                        <li className="breadcrumb-item">
                            <Link to="/">Home</Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">Destinations</li>
                    </ol>
                </nav>
            </div>
        </section>
    );
};

export default Destinationtitle;
