import React, { useState } from 'react';
import UserProfileCard from '../components/UserProfileCard';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { addHotel } from '../redux/hotelSclice';


const AddHotel = ({ adminLoggedIn, loggedIn, setAdminLoggedIn, setLoggedIn }) => {
    const [errorMessage, setErrorMessage] = useState("");
    const navigate = useNavigate();
    const dispatch = useDispatch();





    const [formData, setFormData] = useState({
        name: '',
        price: '',
        address: '',
        city: '',
        postCode: '',
        country: '',
        slug: '',
        images: [],
        about: {
            title: '',
            highlightDescription: ''
        },
        phoneNumber: '',
        email: '',
        policy: {
            priceIncluded: [{ checked: false, title: '', description: '' }],
            forChildren: [{ checked: false, title: '', description: '' }],
            OtherTerms: '',
        },
    });


    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({ ...formData, [name]: type === 'checkbox' ? checked : value });
    };

    const handleNestedChange = (parent, child, field, e) => {
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        setFormData((prevFormData) => {
            const updatedParent = { ...prevFormData[parent] };
            if (field) {
                updatedParent[child] = { ...updatedParent[child], [field]: value };
            } else {
                updatedParent[child] = value;
            }
            return { ...prevFormData, [parent]: updatedParent };
        });
    };
    const handleImageChange = (e) => {
        const files = Array.from(e.target.files);
        setFormData((prevFormData) => ({
            ...prevFormData,
            images: [...prevFormData.images, ...files],
        }));
    };
    const handleArrayChange = (parent, field, index, event) => {
        const { name, type, checked, value } = event.target;
        setFormData((prevFormData) => {
            const updatedArray = [...prevFormData[parent][field]];
            updatedArray[index][name] = type === 'checkbox' ? checked : value;
            return {
                ...prevFormData,
                [parent]: {
                    ...prevFormData[parent],
                    [field]: updatedArray
                }
            };
        });
    };

    const addArrayField = (parent, field) => {
        const newItem = field === 'priceIncluded' || field === 'forChildren'
            ? { checked: false, title: '', description: '' }
            : null;
        if (newItem) {
            setFormData((prevFormData) => ({
                ...prevFormData,
                [parent]: {
                    ...prevFormData[parent],
                    [field]: [...prevFormData[parent][field], newItem],
                },
            }));
        }
    };


    const addImageField = () => {
        setFormData({ ...formData, images: [...formData.images, ''] });
    };
    const handleImageRemove = (index) => {
        const newImages = formData.images.filter((_, i) => i !== index);
        setFormData({ ...formData, images: newImages });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formDataToSend = new FormData();

        // Append form fields to FormData
        for (const key in formData) {
            if (key === 'about' || key === 'policy') {
                // Convert nested objects to JSON strings
                formDataToSend.append(key, JSON.stringify(formData[key]));
            } else if (Array.isArray(formData[key])) {
                if (key === 'images') {
                    formData[key].forEach((file) => {
                        formDataToSend.append('images', file); // Append each image file separately
                    });
                } else {
                    formDataToSend.append(key, JSON.stringify(formData[key])); // Convert other arrays to JSON strings
                }
            } else {
                formDataToSend.append(key, formData[key]); // Append other fields directly
            }
        }

        // Add destination to formDataToSend
        // console.log(formDataToSend);


        try {
            const response = await axios.post('/admin/api/addHotels', formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            const newProduct = response.data;
            // console.log(newProduct);

            dispatch(addHotel(newProduct));

            // Reset form state after successful submission
            setFormData({
                name: '',
                price: '',
                address: '',
                city: '',
                postCode: '',
                country: '',
                images: [],
                about: { title: '', highlightDescription: '' },
                phoneNumber: '',
                email: '',
                slug: '',
                policy: {
                    priceIncluded: [{ checked: false, title: '', description: '' }],
                    forChildren: [{ checked: false, title: '', description: '' }],
                    OtherTerms: '',
                },
            });

            navigate('/addhotel');

        } catch (error) {
            console.error("There was an error adding the product!", error.message);
            setErrorMessage("There was an error adding the product!");
        }
    };


    return (
        <main>
            <div className="p-top-90 p-bottom-90 bg-gray-gradient" >
                {/* <!-- Title --> */}
                <section className="container">
                    <div className="d-lg-flex align-items-lg-end pb-4">
                        <div className="block-title me-auto">
                            <small className="sub-title">My Account</small>
                            <h1 className="display-5 title">Hotels</h1>
                        </div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mt-3">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Add Hotels</li>
                            </ol>
                        </nav>
                    </div>
                </section>
                {/* <!-- /Title --> */}

                {/* <!-- Settings --> */}
                <section className="container">
                    <div className="row g-0 g-xl-8">
                        <div className="col-12 col-xl-9">
                            {/* <!-- Your Profile --> */}
                            <div className="pe-xl-4 me-xl-2">
                                <div className="card border-0 shadow-sm">
                                    <div className="card-body">
                                        <div className="d-flex align-items-center justify-content-between border-bottom pb-4 mb-4">
                                            <h2 className="h3 text-body-emphasis mb-0">Add hotels</h2>
                                        </div>
                                        {errorMessage && (
                                            <div className="alert alert-danger">
                                                {errorMessage}
                                            </div>
                                        )}
                                        <form className="form-container" onSubmit={handleSubmit}>
                                            <div className="form-group">
                                                <label>Name:</label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    name="name"
                                                    value={formData.name}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label>Slug:</label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    name="slug"
                                                    value={formData.slug}
                                                    onChange={handleChange}
                                                />
                                            </div>

                                            <div className="form-group">
                                                <label>Price:</label>
                                                <input
                                                    className="form-control"
                                                    type="number"
                                                    name="price"
                                                    value={formData.price}
                                                    onChange={handleChange}
                                                />
                                            </div>

                                            <div className="form-group">
                                                <label>Address:</label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    name="address"
                                                    value={formData.address}
                                                    onChange={handleChange}
                                                />
                                            </div>

                                            <div className="form-group">
                                                <label>City:</label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    name="city"
                                                    value={formData.city}
                                                    onChange={handleChange}
                                                />
                                            </div>

                                            <div className="form-group">
                                                <label>Post Code:</label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    name="postCode"
                                                    value={formData.postCode}
                                                    onChange={handleChange}
                                                />
                                            </div>

                                            <div className="form-group">
                                                <label>Country:</label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    name="country"
                                                    value={formData.country}
                                                    onChange={handleChange}
                                                />
                                            </div>

                                            <div className="form-group">
                                                <label>Images:</label>
                                                <input
                                                    type="file"
                                                    className="form-control"
                                                    accept="image/*"
                                                    multiple
                                                    onChange={handleImageChange}
                                                    name="images"
                                                />
                                                <button type="button" className="btn btn-secondary mt-2" onClick={addImageField}>
                                                    Add More Images
                                                </button>
                                                {formData.images.map((image, index) => (
                                                    <div key={index}>
                                                        <span>{image.imageAddress || 'Image selected'}</span>
                                                        <button type="button" onClick={() => handleImageRemove(index)}>Remove</button>
                                                    </div>
                                                ))}
                                            </div>

                                            <div className="form-group">
                                                <label>About - Title:</label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    name="highlightTitle"
                                                    value={formData.about.title}
                                                    onChange={(e) => handleNestedChange('about', 'title', null, e)}
                                                />
                                            </div>

                                            <div className="form-group">
                                                <label>About - Description:</label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    name="highlightDescription"
                                                    value={formData.about.highlightDescription}
                                                    onChange={(e) => handleNestedChange('about', 'highlightDescription', null, e)}
                                                />
                                            </div>


                                            <div className="form-group">
                                                <label>Phone Number:</label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    name="phoneNumber"
                                                    value={formData.phoneNumber}
                                                    onChange={handleChange}
                                                />
                                            </div>

                                            <div className="form-group">
                                                <label>Email:</label>
                                                <input
                                                    className="form-control"
                                                    type="email"
                                                    name="email"
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                />
                                            </div>

                                            <div className="form-group">
                                                <label>Policy - Price Included:</label>
                                                {formData.policy.priceIncluded.map((price, index) => (
                                                    <div key={index}>
                                                        <input
                                                            type="checkbox"
                                                            name="checked"
                                                            checked={price.checked}
                                                            onChange={(event) => handleArrayChange('policy', 'priceIncluded', index, event)}
                                                        />
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            name="title"
                                                            placeholder="Title"
                                                            value={price.title}
                                                            onChange={(event) => handleArrayChange('policy', 'priceIncluded', index, event)}
                                                        />
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            name="description"
                                                            placeholder="Description"
                                                            value={price.description}
                                                            onChange={(event) => handleArrayChange('policy', 'priceIncluded', index, event)}
                                                        />
                                                    </div>
                                                ))}
                                                <button type="button" className="btn btn-primary" onClick={() => addArrayField('policy', 'priceIncluded')}>
                                                    Add Another Price Included
                                                </button>
                                            </div>

                                            <div className="form-group">
                                                <label>Policy - For Children:</label>
                                                {formData.policy.forChildren.map((child, index) => (
                                                    <div key={index}>
                                                        <input
                                                            type="checkbox"
                                                            name="checked"
                                                            checked={child.checked}
                                                            onChange={(event) => handleArrayChange('policy', 'forChildren', index, event)}
                                                        />
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            name="title"
                                                            placeholder="Title"
                                                            value={child.title}
                                                            onChange={(event) => handleArrayChange('policy', 'forChildren', index, event)}
                                                        />
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            name="description"
                                                            placeholder="Description"
                                                            value={child.description}
                                                            onChange={(event) => handleArrayChange('policy', 'forChildren', index, event)}
                                                        />
                                                    </div>
                                                ))}
                                                <button type="button" className="btn btn-primary" onClick={() => addArrayField('policy', 'forChildren')}>
                                                    Add Another For Children
                                                </button>
                                            </div>

                                            <div className="form-group">
                                                <label>Policy - Other Terms:</label>
                                                <textarea
                                                    className="form-control"
                                                    name="OtherTerms"
                                                    value={formData.policy.OtherTerms}
                                                    onChange={(e) => handleNestedChange('policy', 'OtherTerms', null, e)}
                                                />
                                            </div>

                                            <button type="submit" className="btn btn-primary">Submit</button>
                                        </form>

                                    </div>
                                </div>
                            </div>
                            {/* <!-- /Your Profile --> */}
                        </div>
                        <div className="col-12 col-xl-3">
                            {/* <!-- Account menu --> */}
                            <div className="card border-0 shadow-sm sticky-top sticky-top-120 d-none d-xl-block z-0">
                                <UserProfileCard adminLoggedIn={adminLoggedIn} loggedIn={loggedIn} setAdminLoggedIn={setAdminLoggedIn} setLoggedIn={setLoggedIn} />
                            </div>
                            {/* <!-- Account menu --> */}
                        </div>
                    </div>
                </section>
                {/* <!-- /Settings --> */}
            </div>
        </main>
    );
};

export default AddHotel;
