import React, { useEffect, useState } from 'react';
import UserProfileCard from '../components/UserProfileCard';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { updateProduct } from '../redux/productSlice';
import { fetchDestination } from '../redux/destinationSlice';
import { fetchProducts } from '../redux/productSlice';

const UpdateProduct = ({ adminLoggedIn, loggedIn, setAdminLoggedIn, setLoggedIn }) => {

    const [errorMessage, setErrorMessage] = useState("");
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [destination, setDestination] = useState([]);
    const { destinations, loading: loadingDestination, error: errorDestination } = useSelector((state) => state.destination);
    const { products, loading: loadingProduct, error: errorProduct } = useSelector((state) => state.product);
    const [filterproductID, setfilterproductID] = useState(); // Corrected this line

    const [formData, setFormData] = useState({
        name: '',
        price: 0,
        highlights: [{ title: '', highlightDescription: '' }],
        description: '',
        iteracyDescription: '',
        curriculums: [{ title: '', curriculumDescription: '' }],
        priceIncluded: [{ checked: false, title: '', description: '' }],
        forChildren: [{ checked: false, title: '', description: '' }],
        whyChoose: [{ title: '', description: '' }],
        otherTerms: '',
        images: [],
        days: 0,
        location: 0,
        tourCode: '',
        startLocation: '',
        endLocation: '',
        typeTour: '',
        ageRange: '',
        language: '',
        payments: '',
        isCancel: false,
        rating: 0,
        slug: '',
    });


    useEffect(() => {
        dispatch(fetchDestination())
        dispatch(fetchProducts())
    }, [dispatch])




    // console.log(destinations);
    // console.log(products);


    const { id } = useParams()


    useEffect(() => {
        const filterproduct = products.find((item) =>
            item.slug.toLowerCase().replace(/-/g, "") === id.toLowerCase().replace(/-/g, "")
        );

        if (filterproduct) {
            setfilterproductID(filterproduct._id);
            setFormData({
                name: filterproduct.name || '',
                price: filterproduct.price || 0,
                highlights: filterproduct.about?.highlight?.map(h => ({
                    title: h.title || '',
                    highlightDescription: h.highlightDescription || ''
                })) || [{ title: '', highlightDescription: '' }],
                description: filterproduct.about?.description || '',
                iteracyDescription: filterproduct.iteracy?.iteracyDescription || '',
                curriculums: filterproduct.iteracy?.curriculum?.map(c => ({
                    title: c.title || '',
                    curriculumDescription: c.curriculumDescription || ''
                })) || [{ title: '', curriculumDescription: '' }],
                priceIncluded: filterproduct.policy?.priceIncluded?.map(p => ({
                    checked: p.checked || false,
                    title: p.title || '',
                    description: p.description || ''
                })) || [{ checked: false, title: '', description: '' }],
                forChildren: filterproduct.policy?.forChildren?.map(f => ({
                    checked: f.checked || false,
                    title: f.title || '',
                    description: f.description || ''
                })) || [{ checked: false, title: '', description: '' }],
                whyChoose: filterproduct.whychooseus?.map(f => ({
                    title: f.title || '',
                    description: f.description || ''
                })) || [{ title: '', description: '' }],
                otherTerms: filterproduct.policy?.OtherTerms || '',
                images: filterproduct.image?.map(img => img.imageAdress) || [],
                days: filterproduct.days || 0,
                location: filterproduct.location || 0,
                tourCode: filterproduct.tourCode || '',
                startLocation: filterproduct.startLocation || '',
                endLocation: filterproduct.endLocation || '',
                typeTour: filterproduct.typeTour || '',
                ageRange: filterproduct.ageRange || '',
                language: filterproduct.language || '',
                payments: filterproduct.payments || '',
                isCancel: filterproduct.isCancel || false,
                rating: filterproduct.rating || 0,
                slug: filterproduct.slug || '',
            });
        }
    }, [products, id]);






    const handleDestinationChange = (e) => {
        const options = e.target.options;
        const selectedValues = [];
        for (let i = 0; i < options.length; i++) {
            if (options[i].selected) {
                selectedValues.push(options[i].value);
            }
        }
        setDestination(selectedValues);
        // console.log("Updated Destinations:", selectedValues);

    };









    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({ ...formData, [name]: type === 'checkbox' ? checked : value });
    };
    const handleArrayChange = (field, index, event) => {
        const values = [...formData[field]];
        const { name, type, checked, value } = event.target;
        values[index][name] = type === 'checkbox' ? checked : value;
        setFormData({ ...formData, [field]: values });
    };

    const addArrayField = (field) => {
        let newItem;
        if (field === 'highlights') {
            newItem = { title: '', highlightDescription: '' };
        } else if (field === 'curriculums') {
            newItem = { title: '', curriculumDescription: '' };
        } else if (field === 'priceIncluded' || field === 'forChildren') {
            newItem = { checked: false, title: '', description: '' };
        }
        else if (field === 'whyChoose') {
            newItem = { title: '', description: '' }; // Add blank title and description for "Why Choose"
        }
        setFormData({ ...formData, [field]: [...formData[field], newItem] });
    };
    const handleImageChange = (e) => {
        const files = Array.from(e.target.files);
        setFormData((prevFormData) => {
            const updatedImages = [...prevFormData.images, ...files];
            // console.log('Selected files:', updatedImages); // Logging the correct, updated state
            return { ...prevFormData, images: updatedImages };
        });
    };


    const addImageField = () => {
        setFormData({ ...formData, images: [...formData.images, ''] });
    };
    const handleImageRemove = (index) => {
        const newImages = formData.images.filter((_, i) => i !== index);
        setFormData({ ...formData, images: newImages });
    };

    const handleSubmit = async (productId, e) => {
        e.preventDefault();

        const formDataToSend = new FormData();

        // Append form fields to FormData
        for (const key in formData) {
            if (Array.isArray(formData[key])) {
                if (key === 'images') {
                    formData[key].forEach((file) => {
                        formDataToSend.append('images', file);
                    });
                } else {
                    formDataToSend.append(key, JSON.stringify(formData[key]));
                }
            } else {
                formDataToSend.append(key, formData[key]);
            }
        }

        // Add multiple destinations to formDataToSend
        formDataToSend.append('destination', JSON.stringify(destination));

        try {
            const response = await axios.post(`/admin/api/updateProduct/${productId}`, formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            const newProduct = response.data;
            // console.log(newProduct);

            dispatch(updateProduct(newProduct));

            // Reset form state after successful submission
            setFormData({
                name: '',
                price: '',
                highlights: [{ title: '', highlightDescription: '' }],
                description: '',
                iteracyDescription: '',
                curriculums: [{ title: '', curriculumDescription: '' }],
                priceIncluded: [{ checked: false, title: '', description: '' }],
                forChildren: [{ checked: false, title: '', description: '' }],
                whyChoose: [ // Dynamic array for "Why Choose TripNow?"
                    { title: '', description: '' },
                ],

                otherTerms: '',
                images: [],
                days: 0,
                location: 0,
                tourCode: '',
                startLocation: '',
                endLocation: '',
                typeTour: '',
                ageRange: '',
                language: '',
                payments: '',
                isCancel: false,
                rating: 0,
                slug: "",
            });
            setDestination([]);

            navigate('/packages');
        } catch (error) {
            console.error("There was an error adding the product!", error.message);
            setErrorMessage("There was an error adding the product!");
        }
    };

    if (loadingProduct) {
        return <div>Loading...</div>;
    }

    if (errorProduct) {
        return <div>Error fetching cabs.</div>;
    }



    return (
        <main>
            <div className="p-top-90 p-bottom-90 bg-gray-gradient" >
                {/* <!-- Title --> */}
                <section className="container">
                    <div className="d-lg-flex align-items-lg-end pb-4">
                        <div className="block-title me-auto">
                            <small className="sub-title">My Account</small>
                            <h1 className="display-5 title">Settings</h1>
                        </div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mt-3">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Settings</li>
                            </ol>
                        </nav>
                    </div>
                </section>
                {/* <!-- /Title --> */}

                {/* <!-- Settings --> */}
                <section className="container">
                    <div className="row g-0 g-xl-8">
                        <div className="col-12 col-xl-9">
                            {/* <!-- Your Profile --> */}
                            <div className="pe-xl-4 me-xl-2">
                                <div className="card border-0 shadow-sm">
                                    <div className="card-body">
                                        <div className="d-flex align-items-center justify-content-between border-bottom pb-4 mb-4">
                                            <h2 className="h3 text-body-emphasis mb-0">Update {formData.name}</h2>
                                        </div>
                                        {errorMessage && (
                                            <div className="alert alert-danger">
                                                {errorMessage}
                                            </div>
                                        )}
                                        <form className="form-container" onSubmit={(e) => handleSubmit(filterproductID, e)}>
                                            <div className="col-4 col-md-6">
                                                <div className="mb-4">
                                                    <label className="form-label" htmlFor="selCountry">Destinations<span className="text-danger">*</span></label>
                                                    <select
                                                        className="form-select dropdown-select shadow-sm"
                                                        id="selCountry"

                                                        multiple
                                                        value={destination}
                                                        onChange={handleDestinationChange}
                                                    >
                                                        <option value="" disabled>Select destinations</option>
                                                        {!loadingDestination && !errorDestination && destinations.length > 0 && destinations.map((data) => (
                                                            <option key={data.value} value={data.name}>
                                                                {data.name}
                                                            </option>
                                                        ))}

                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-4 col-md-6">
                                                <div className="mb-4">

                                                    <label>Rating:</label>
                                                    <input
                                                        className="form-control"
                                                        type="number"
                                                        name="rating"
                                                        value={formData.rating}
                                                        onChange={handleChange}
                                                        min={0}
                                                        max={5}
                                                        step={0.1}

                                                    />
                                                </div>
                                            </div>
                                            <div className="col-4 col-md-6">
                                                <div className="mb-4">

                                                    <label>Slug:</label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        name="slug"
                                                        value={formData.slug}
                                                        onChange={handleChange}

                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label>Images:</label>
                                                <input
                                                    type="file"
                                                    className="form-control"
                                                    accept="image/*"
                                                    multiple
                                                    onChange={handleImageChange}
                                                    name='images'

                                                />
                                                <button type="button" className="btn btn-secondary mt-2" onClick={addImageField}>
                                                    Add More Images
                                                </button>
                                                {formData.images.map((image, index) => (
                                                    <div key={index}>
                                                        <span>{'Image selected'}</span>
                                                        <button type="button" onClick={() => handleImageRemove(index)}>Remove</button>
                                                    </div>
                                                ))}
                                            </div>
                                            <div className="form-group">
                                                <label>Name:</label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    name="name"
                                                    value={formData.name}
                                                    onChange={handleChange}

                                                />
                                            </div>

                                            <div className="form-group">
                                                <label>Price:</label>
                                                <input
                                                    className="form-control"
                                                    type="number"
                                                    name="price"
                                                    value={formData.price}
                                                    onChange={handleChange}

                                                />
                                            </div>
                                            <div className="form-group">
                                                <label>Location:</label>
                                                <input
                                                    className="form-control"
                                                    type="number"
                                                    name="location"
                                                    value={formData.location}
                                                    onChange={handleChange}

                                                />
                                            </div>

                                            <div className="form-group">
                                                <label>Tour Code:</label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    name="tourCode"
                                                    value={formData.tourCode}
                                                    onChange={handleChange}

                                                />
                                            </div>

                                            <div className="form-group">
                                                <label>Start Location:</label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    name="startLocation"
                                                    value={formData.startLocation}
                                                    onChange={handleChange}

                                                />
                                            </div>

                                            <div className="form-group">
                                                <label>End Location:</label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    name="endLocation"
                                                    value={formData.endLocation}
                                                    onChange={handleChange}

                                                />
                                            </div>

                                            <div className="form-group">
                                                <label>Type of Tour:</label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    name="typeTour"
                                                    value={formData.typeTour}
                                                    onChange={handleChange}

                                                />
                                            </div>

                                            <div className="form-group">
                                                <label>Age Range:</label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    name="ageRange"
                                                    value={formData.ageRange}
                                                    onChange={handleChange}

                                                />
                                            </div>

                                            <div className="form-group">
                                                <label>Language:</label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    name="language"
                                                    value={formData.language}
                                                    onChange={handleChange}

                                                />
                                            </div>

                                            <div className="form-group">
                                                <label>Payments:</label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    name="payments"
                                                    value={formData.payments}
                                                    onChange={handleChange}

                                                />
                                            </div>

                                            <div className="form-group">
                                                <label>Cancel:</label>
                                                <input
                                                    type="checkbox"
                                                    name="isCancel"
                                                    checked={formData.isCancel}
                                                    onChange={handleChange}

                                                />
                                            </div>

                                            <div className="form-group">
                                                <label>Highlights:</label>
                                                {formData.highlights.map((highlight, index) => (
                                                    <div key={index}>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            name="title"
                                                            placeholder="Title"
                                                            value={highlight.title}
                                                            onChange={(event) => handleArrayChange('highlights', index, event)}
                                                            style={{ marginBottom: "10px" }}

                                                        />
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            name="highlightDescription"
                                                            placeholder="Highlight Description"
                                                            value={highlight.highlightDescription}
                                                            onChange={(event) => handleArrayChange('highlights', index, event)}
                                                            style={{ marginBottom: "10px" }}

                                                        />
                                                    </div>
                                                ))}
                                                <button type="button" className="btn btn-primary" onClick={() => addArrayField('highlights')}>
                                                    Add Another Highlight
                                                </button>
                                            </div>

                                            <div className="form-group">
                                                <label>Description:</label>
                                                <textarea
                                                    className="form-control"
                                                    name="description"
                                                    value={formData.description}
                                                    onChange={handleChange}

                                                />
                                            </div>

                                            <div className="form-group">
                                                <label>Iteracy Description:</label>
                                                <textarea
                                                    className="form-control"
                                                    name="iteracyDescription"
                                                    value={formData.iteracyDescription}
                                                    onChange={handleChange}

                                                />
                                            </div>

                                            <div className="form-group">
                                                <label>Curriculum:</label>
                                                {formData.curriculums.map((curriculum, index) => (
                                                    <div key={index}>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            name="title"
                                                            placeholder="Title"
                                                            value={curriculum.title}
                                                            onChange={(event) => handleArrayChange('curriculums', index, event)}
                                                            style={{ marginBottom: "10px" }}

                                                        />
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            name="curriculumDescription"
                                                            placeholder="Curriculum Description"
                                                            value={curriculum.curriculumDescription}
                                                            onChange={(event) => handleArrayChange('curriculums', index, event)}
                                                            style={{ marginBottom: "10px" }}

                                                        />
                                                    </div>
                                                ))}
                                                <button type="button" className="btn btn-primary" onClick={() => addArrayField('curriculums')}>
                                                    Add Another Curriculum
                                                </button>
                                            </div>

                                            <div className="form-group">
                                                <label>Price Included:</label>
                                                {formData.priceIncluded.map((price, index) => (
                                                    <div key={index}>
                                                        <input
                                                            type="checkbox"
                                                            name="checked"
                                                            checked={price.checked}
                                                            onChange={(event) => handleArrayChange('priceIncluded', index, event)}
                                                        />
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            name="title"
                                                            placeholder="Title"
                                                            value={price.title}
                                                            onChange={(event) => handleArrayChange('priceIncluded', index, event)}
                                                            style={{ marginBottom: "10px" }}

                                                        />
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            name="description"
                                                            placeholder="Description"
                                                            value={price.description}
                                                            onChange={(event) => handleArrayChange('priceIncluded', index, event)}
                                                            style={{ marginBottom: "10px" }}

                                                        />
                                                    </div>
                                                ))}
                                                <button type="button" className="btn btn-primary" onClick={() => addArrayField('priceIncluded')}>
                                                    Add Another Price Included
                                                </button>
                                            </div>

                                            <div className="form-group">
                                                <label>For Children:</label>
                                                {formData.forChildren.map((child, index) => (
                                                    <div key={index}>
                                                        <input
                                                            type="checkbox"
                                                            name="checked"
                                                            checked={child.checked}
                                                            onChange={(event) => handleArrayChange('forChildren', index, event)}
                                                        />
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            name="title"
                                                            placeholder="Title"
                                                            value={child.title}
                                                            onChange={(event) => handleArrayChange('forChildren', index, event)}
                                                            style={{ marginBottom: "10px" }}

                                                        />
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            name="description"
                                                            placeholder="Description"
                                                            value={child.description}
                                                            onChange={(event) => handleArrayChange('forChildren', index, event)}
                                                            style={{ marginBottom: "10px" }}

                                                        />
                                                    </div>
                                                ))}
                                                <button type="button" className="btn btn-primary" onClick={() => addArrayField('forChildren')}>
                                                    Add Another For Children
                                                </button>
                                            </div>



                                            <div className="form-group">
                                                <label>Other Terms:</label>
                                                <textarea
                                                    className="form-control"
                                                    name="otherTerms"
                                                    value={formData.otherTerms}
                                                    onChange={handleChange}

                                                />
                                            </div>
                                            <div className="form-group">
                                                <label>Why Choose TripNow?</label>
                                                {formData.whyChoose.map((item, index) => (
                                                    <div key={index} style={{ marginBottom: "15px" }}>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            name="title"
                                                            placeholder="Title (e.g., Local Expertise)"
                                                            value={item.title}
                                                            onChange={(event) => handleArrayChange('whyChoose', index, event)}
                                                            style={{ marginBottom: "5px" }}
                                                            required
                                                        />
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            name="description"
                                                            placeholder="Description"
                                                            value={item.description}
                                                            onChange={(event) => handleArrayChange('whyChoose', index, event)}
                                                            required
                                                        />
                                                    </div>
                                                ))}
                                                <button
                                                    type="button"
                                                    className="btn btn-primary"
                                                    onClick={() => addArrayField('whyChoose')}
                                                    style={{ margin: "10px 0" }}
                                                >
                                                    Add Another Option
                                                </button>
                                            </div>
                                            <button type="submit" className="btn btn-primary">Submit</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- /Your Profile --> */}
                        </div>
                        <div className="col-12 col-xl-3">
                            {/* <!-- Account menu --> */}
                            <div className="card border-0 shadow-sm sticky-top sticky-top-120 d-none d-xl-block z-0">
                                <UserProfileCard adminLoggedIn={adminLoggedIn} loggedIn={loggedIn} setAdminLoggedIn={setAdminLoggedIn} setLoggedIn={setLoggedIn} />
                            </div>
                            {/* <!-- Account menu --> */}
                        </div>
                    </div>
                </section>
                {/* <!-- /Settings --> */}
            </div>
        </main>)
}

export default UpdateProduct