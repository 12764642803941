import React from 'react'
import { Link } from 'react-router-dom'

const Tourtypes = () => {
    return (
        <section className="p-top-90 p-bottom-90 bg-gray-gradient">
            <div className="container">
                {/* <!-- Types --> */}
                <div className="row g-3 g-xl-4">
                    <div className="col-12 col-xl-3 col-md-6">
                        {/* <!-- Title --> */}
                        <div className="info-card shadow-sm rounded h-100 active">
                            <div className="block-title">
                                <small className="sub-title card-title">Flexible tours</small>
                                <h2 className="h1 title card-title lh-xs">Travel Your Way
                                </h2>
                            </div>
                            <p className="card-desc">Whether you prefer solo adventures, family vacations, or group escapes, Travel Your Way with TripNow. Your journey, your style, your unforgettable memories.</p>

                        </div>
                        {/* <!-- /Title --> */}
                    </div>
                    <div className="col-12 col-xl-3 col-md-6">
                        <Link to="/tour-packages" className="info-card hover-effect shadow-sm rounded h-100">
                            <div className="card-icon">
                                <i className="hicon hicon-family-with-teens"></i>
                            </div>
                            <h3 className="h4 card-title">Group tours</h3>
                            <p className="card-desc">Experience the joy of exploring with like-minded travelers. Our Group Tours are designed for camaraderie, adventure, and shared moments, all while discovering incredible destinations together.
                            </p>

                        </Link>
                    </div>
                    <div className="col-12 col-xl-3 col-md-6">
                        <Link to="/tour-packages" className="info-card hover-effect shadow-sm rounded h-100">
                            <div className="card-icon">
                                <i className="hicon hicon-regular-travel-protection"></i>
                            </div>
                            <h3 className="h4 card-title">Private tours</h3>
                            <p className="card-desc">Embark on a journey that's all about you. With Private Tours, enjoy personalized itineraries, exclusive experiences, and the flexibility to travel at your own pace.
                            </p>

                        </Link>
                    </div>
                    <div className="col-12 col-xl-3 col-md-6">
                        <Link to="/contact-us" className="info-card hover-effect shadow-sm rounded h-100">
                            <div className="card-icon">
                                <i className="hicon hicon-tours"></i>
                            </div>
                            <h3 className="h4 card-title">Tailor-Made tours</h3>
                            <p className="card-desc">Create your dream journey with our Tailor-Made Tours. Customize every detail, from destinations to experiences, ensuring your trip is as unique as you are.
                            </p>

                        </Link>
                    </div>
                </div>
                {/* <!-- /Types --> */}
            </div>
        </section>
    )
}

export default Tourtypes