import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProducts } from '../redux/productSlice';
import { setUserData, addToCart, removeFromCart } from '../redux/userSlice';
import { fetchDestination } from '../redux/destinationSlice';
import axios from 'axios';

const Tourpackegestours = ({ destination, packages, filter }) => {

    const [errorMessage, setErrorMessage] = useState(""); // State to store error message
    const [successMessage, setSuccessMessage] = useState("");
    const [destinationname, setDestinationName] = useState([])

    const [localSelectedDestination, setLocalSelectedDestination] = useState(
        destination ? [destination.name] : []
    );
    const [priceRange, setPriceRange] = useState({ min: 0, max: 1000 });
    const [review, setreview] = useState('');
    const [appliedFilters, setAppliedFilters] = useState([]);
    const { destinations } = useSelector(state => state.destination);
    useEffect(() => {
        // Extract the names and update the state
        const destinationNames = destinations.map(item => item.name);
        setDestinationName(destinationNames);
    }, [destinations]);
    const loggedIn = useSelector((state) => state.user.isLoggedIn);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userData = useSelector((state) => state.user.userData);
    const [likedProducts, setLikedProducts] = useState(userData?.cart || []);


    const handleDestinationChange = (city) => {
        setLocalSelectedDestination((prevSelectedCities) => {
            if (prevSelectedCities.includes(city)) {
                // Remove city from selected destinations and applied filters if clicked again
                setAppliedFilters((filters) => filters.filter((filter) => filter.label !== city));
                return prevSelectedCities.filter((selectedCity) => selectedCity !== city);
            } else {
                // Add new city to selected destinations and update applied filters
                setAppliedFilters((filters) => [
                    ...filters,
                    { label: city, type: "destination" },
                ]);
                return [...prevSelectedCities, city];
            }
        });
    };


    const handleReviewChange = (star) => {
        setreview((prevSelectedStar) => {
            if (prevSelectedStar === star) {
                // Remove re`xview filter from applied filters if clicked again
                setAppliedFilters((filters) =>
                    filters.filter((filter) => filter.label !== `${star}⭐`)
                );
                return null;
            } else {
                // Replace with the new review star and update applied filters
                setAppliedFilters((filters) => [
                    ...filters.filter((filter) => filter.type !== "review"),
                    { label: `${star}⭐`, type: "review" },
                ]);
                return star;
            }
        });
    };


    const handlePriceRangeChange = (min, max) => {
        setPriceRange({ min, max });

        // Price range filter logic
        if (min || max) {
            setAppliedFilters((filters) => {
                const priceFilterIndex = filters.findIndex(
                    (filter) => filter.type === "price"
                );
                if (priceFilterIndex !== -1) {
                    // Update price range if it exists
                    filters[priceFilterIndex] = { label: `$${min} - $${max}`, type: "price" };
                } else {
                    // Add new price range filter
                    filters.push({ label: `$${min} - $${max}`, type: "price" });
                }
                return [...filters];
            });
        } else {
            // Remove price range filter if both values are empty
            setAppliedFilters((filters) =>
                filters.filter((filter) => filter.type !== "price")
            );
        }
    };


    useEffect(() => {
        dispatch(fetchDestination());
    }, [dispatch]);

    const filteredDestination = destinations.filter((destination) =>
        localSelectedDestination.includes(destination.name)
    );



    const { products, loading, error } = useSelector((state) => state.product);



    // Filtering logic based on applied filters
    const filteredProduct = products.filter((product) => {
        // Check if packages is provided
        if (packages) {
            // Filter products based on matching names with packages
            if (!product.name.toLowerCase().includes(packages.toLowerCase())) {
                return false; // Exclude products whose names do not include the package name
            }
        }

        // Filter by selected destination if applied
        if (localSelectedDestination && localSelectedDestination.length > 0) {
            // Ensure product's destination is in the list of selected destinations
            const matchingDestinations = localSelectedDestination.some((city) =>
                filteredDestination.some((destination) =>
                    destination.name === city && destination.products.includes(product._id)
                )
            );
            if (!matchingDestinations) {
                return false;
            }
        }

        // Filter by price range if applied
        if (priceRange.min && priceRange.max) {
            const productPrice = parseFloat(product.price); // Assuming product.price is a numeric value or string
            const minPrice = parseFloat(priceRange.min);
            const maxPrice = parseFloat(priceRange.max);

            if (isNaN(productPrice) || productPrice < minPrice || productPrice > maxPrice) {
                return false;
            }
        }

        // Filter by review rating if applied
        if (review) {
            const reviewScore = parseFloat(review); // Ensure review is a number

            if (product.rating < reviewScore) { // Assuming product.review is a numeric score
                return false; // Exclude products with a review score less than the selected rating
            }
        }

        // If all conditions pass, include the product in the filtered list
        return true;
    });


    const removeFilter = (index) => {
        const filterToRemove = appliedFilters[index];

        // Update appliedFilters state by removing the selected filter
        setAppliedFilters((filters) => filters.filter((_, i) => i !== index));

        // Remove the specific filter based on its type
        switch (filterToRemove.type) {
            case "destination":
                setLocalSelectedDestination((prevSelectedCities) =>
                    prevSelectedCities.filter((city) => city !== filterToRemove.label)
                );
                break;
            case "review":
                setreview(null); // Reset the review filter
                break;
            case "price":
                setPriceRange({ min: null, max: null }); // Reset the price range
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        dispatch(fetchProducts());
        if (loggedIn) {
            axios.get("/user/api/getUserData")
                .then(response => {
                    dispatch(setUserData(response.data.userData));
                })
                .catch(error => {
                    console.error("Error fetching user data:", error.message);
                });
        }
    }, [dispatch, loggedIn]);

    // Handle product selection based on dropdown choice
    // console.log(products);


    const getImageSrc = (picture) => {
        if (!picture) {
            return '../../assets/img/avatars/a3@2x.jpg';
        }
        return picture.startsWith('http') ? picture : picture;
    };

    const onLikeProduct = async (productId) => {
        if (!loggedIn) {
            navigate("/login");
            return;
        }

        try {
            const response = await axios.post("/user/api/likedProduct", { productId });
            // console.log(response.data.message);
            document.getElementById(`likeButton${productId}`).style.color = "#5143d9"

            if (response.data.userData) {
                dispatch(setUserData(response.data.userData));
                setSuccessMessage("Product liked and added to your wishlist successfully!");
                setErrorMessage("");
            } else {
                dispatch(addToCart(productId));
                setSuccessMessage("Product liked and added to your wishlist successfully!");
                setErrorMessage("");
            }
        } catch (error) {
            // console.error("Error liking the product:", error.response ? error.response.data.message : error.message);
            if (error.response) {
                if (error.response.status === 409) {
                    // Handle the case where the product is already liked
                    try {
                        const response = await axios.post("/user/api/unlikedProduct", { productId });
                        // console.log(response.data.message);
                        setLikedProducts((prev) => prev.filter((id) => id !== productId)); // Remove from liked products
                        document.getElementById(`likeButton${productId}`).style.color = "grey"

                        document.getElementById(`likeButton${productId}`).classList.remove("liked");
                        // Assuming the API returns the updated userData
                        if (response.data.userData) {
                            dispatch(setUserData(response.data.userData));
                            setSuccessMessage("Product removed from your wishlist successfully!");
                        } else {
                            // If the API doesn't return userData, manually update the cart
                            dispatch(removeFromCart(productId));
                            setSuccessMessage("Product removed from your wishlist successfully!");
                        }
                    } catch (error) {
                        console.error("Error disliking the product:", error.response ? error.response.data.message : error.message);
                    }
                } else {
                    // Handle other errors
                    setErrorMessage(error.response.data.message);
                    setSuccessMessage("");
                }
            } else {
                setErrorMessage(error.message); // Handle network or other errors
                setSuccessMessage("");
            }

            console.error("Error liking the product:", errorMessage);
        }
    };

    return (
        <section className="p-top-90 p-bottom-90 bg-gray-gradient">
            <div className="container">
                <div className="mb-4">
                    <div className="row g-0 g-md-2 align-items-end">
                        <div className="col-12 col-xl-4">
                            {/* <!-- Title --> */}
                            <div className="block-title me-auto">
                                <small className="sub-title">   Explore {destination ? destination.name : "TripNow"}</small>
                                <h2 className="h1 title">Tours in {destination ? destination.name : "TripNow"}</h2>
                            </div>
                            {/* <!-- /Title --> */}
                        </div>
                        {appliedFilters.length > 0 && (
                            <div className="col-12 col-xl-8">
                                <ul className="filtered-list mb-4 text-xl-end">
                                    <li className="filtered-item">
                                        <strong>Filtered:</strong>
                                    </li>
                                    {appliedFilters.map((filter, index) => (
                                        <li key={index} className="filtered-item">
                                            <button type="button">
                                                <span>{filter.label}</span>
                                                <i className="hicon hicon-line-close hicon-bold" onClick={() => removeFilter(index)}></i>
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}

                    </div>
                </div>
                <div className="row">
                    {
                        filter && <div className="col-12 col-xxl-3 col-xl-4">
                            {/* <!-- Filter --> */}

                            <aside className="mb-4 ps-xl-2">
                                <div className="card border-0 shadow-sm">
                                    <div className="card-body">
                                        <h2 className="h3 mb-4">Filter by</h2>
                                        <form onSubmit={(e) => { e.preventDefault() }}>
                                            {/* <!-- Filter list --> */}
                                            <div className="accordion accordion-filter" id="acdFilterTourGroup1">
                                                {/* <!-- Filter by Destinations --> */}
                                                <div className="accordion-item">
                                                    <h3 className="accordion-header" id="tourGroupHeading3">
                                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#tourGroup3" aria-expanded="true" aria-controls="tourGroup3">
                                                            Destinations
                                                        </button>
                                                    </h3>
                                                    <div id="tourGroup3" className="accordion-collapse collapse show" aria-labelledby="tourGroupHeading3">
                                                        <div className="accordion-body">
                                                            <div className="row g-2">
                                                                {destinationname.map((city, index) => (
                                                                    <div key={index} className="col-6">
                                                                        <div className="form-check">
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="checkbox"
                                                                                id={`chkCat${index + 1}12`}
                                                                                checked={localSelectedDestination.includes(city)} // Check if city is in the selected destinations array
                                                                                onChange={() => handleDestinationChange(city)}
                                                                            />
                                                                            <label className="form-check-label" htmlFor={`chkCat${index + 1}12`}>
                                                                                {city}
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                {/* <!-- /Filter by Destinations -->
                                            <!-- Filter by Price --> */}
                                                <div className="accordion-item">
                                                    <h3 className="accordion-header" id="tourGroupHeading4">
                                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#tourGroup4" aria-expanded="true" aria-controls="tourGroup4">
                                                            Price
                                                        </button>
                                                    </h3>
                                                    <div id="tourGroup4" className="accordion-collapse collapse show" aria-labelledby="tourGroupHeading4">
                                                        <div className="accordion-body">
                                                            <div className="price-range">
                                                                <div className="row g-2 price-input">
                                                                    <div className="col-6">
                                                                        <label htmlFor="txtMinPrice" className="form-label">Min price</label>
                                                                        <div className="input-group mb-3">
                                                                            <span className="input-group-text bg-white">₹</span>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control input-min shadow-sm"
                                                                                aria-label="Min price"
                                                                                value={priceRange.min}
                                                                                id="txtMinPrice"
                                                                                onChange={(e) =>
                                                                                    handlePriceRangeChange(e.target.value, priceRange.max)
                                                                                }
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <label htmlFor="txtMaxPrice" className="form-label">Max price</label>
                                                                        <div className="input-group mb-3">
                                                                            <span className="input-group-text bg-white">₹</span>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control input-max shadow-sm"
                                                                                aria-label="Max price"
                                                                                value={priceRange.max}
                                                                                id="txtMaxPrice"
                                                                                onChange={(e) =>
                                                                                    handlePriceRangeChange(priceRange.min, e.target.value)
                                                                                }
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* <!-- /Filter by Price -->
                                            <!-- Filter by Departs from --> */}
                                                <div className="accordion-item">
                                                    <h3 className="accordion-header" id="tourGroupHeading7">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#tourGroup7" aria-expanded="false" aria-controls="tourGroup3">
                                                            Reviews
                                                        </button>
                                                    </h3>
                                                    <div id="tourGroup7" className="accordion-collapse collapse" aria-labelledby="tourGroupHeading7">
                                                        <div className="accordion-body">
                                                            <div className="row g-2">
                                                                {[5, 4, 3, 2, 1].map(star => (
                                                                    <div className="col-6" key={star}>
                                                                        <div className="form-check">
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="checkbox"
                                                                                id={`review${star}`}
                                                                                checked={review === star}
                                                                                onChange={() => handleReviewChange(star)}
                                                                            />
                                                                            <label className="form-check-label" htmlFor={`review${star}`}>
                                                                                {star}⭐
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* <!-- /Filter by Departs from --> */}
                                            </div>
                                            {/* <!-- /Filter list -->
                                        <!-- Buttons --> */}

                                            {/* <!-- Buttons --> */}
                                        </form>
                                    </div>
                                </div>
                            </aside>

                            {/* <!-- /Filter form --> */}
                        </div>
                    }
                    <div className="col-12 col-xxl-9 col-xl-8">
                        <div className="tour-grid mb-5 mb-xl-0">
                            <div className="row">
                                {successMessage && (
                                    <div className="alert alert-success">
                                        {successMessage}
                                    </div>
                                )}
                                {errorMessage && (
                                    <div className="alert alert-danger">
                                        {errorMessage}
                                    </div>
                                )}
                                {!error && !loading && products.length > 0 ? (
                                    filteredProduct.map((product) => (
                                        <div className="col-12 col-xxl-4 col-md-6" key={product._id}>
                                            {/* Tour */}
                                            <div className="tour-item rounded shadow-sm hover-effect mb-4">
                                                <div className="tour-img">
                                                    <Link to={`/tour-packages/${product.slug.split(' ').join("-").toLowerCase()}`} state={{ product, products }}>
                                                        <figure className="image-hover image-hover-overlay">
                                                            <img
                                                                src={`/${getImageSrc(product.image[0].imageAddress)}`}
                                                                alt={product.name}
                                                                height={200}
                                                            />
                                                            <i className="hicon hicon-plus-thin image-hover-icon"></i>
                                                        </figure>
                                                    </Link>
                                                    <div className="tour-like">
                                                        <button
                                                            onClick={() => onLikeProduct(product._id)}
                                                            type="button"
                                                            id={`likeButton${product._id}`}
                                                            className={`circle-icon like-icon ${likedProducts.includes(product._id) ? "liked" : ""}`}

                                                        >
                                                            <i className="hicon hicon-favorite-filled"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="tour-content">
                                                    <h3 className="tour-title">
                                                        <Link to={`/tour-packages/${product.slug.split(' ').join("-").toLowerCase()}`} state={{ product, products }} >
                                                            {product.name}
                                                        </Link>
                                                    </h3>
                                                    <div className="tour-itinerary">
                                                        <span>
                                                            <i className="hicon hicon-menu-calendar"></i>{` ${product.iteracy?.curriculum.length} days`}</span>

                                                        <span>
                                                            <i className="hicon hicon-flights-pin"></i>
                                                            {` ${product.location} Destinations`}
                                                        </span>
                                                    </div>
                                                    <div className="inline-review mb-4">
                                                        <span className="review-score">{product.rating}</span>
                                                        <span className="star-rate-view star-rate-size-sm me-2">
                                                            <span className="star-value rate-50"></span>
                                                        </span>
                                                        <small className="review-total">
                                                            <span>(231 reviews)</span>
                                                        </small>
                                                    </div>
                                                    <div className="tour-booking d-flex align-items-center">
                                                        <div className="tour-price me-auto">
                                                            <strong>
                                                                <sup>₹</sup>
                                                                {product.price}
                                                            </strong>
                                                            <span>
                                                                <sup>₹</sup>
                                                                <del>500.00</del>
                                                            </span>
                                                        </div>
                                                        <Link to={`/tour-packages/${product.slug.split(' ').join("-").toLowerCase()}`} state={{ product, products }} className="circle-icon circle-icon-link">
                                                            <i className="hicon hicon-flights-one-ways"></i>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* /Tour */}
                                        </div>

                                    ))
                                ) : (
                                    <p>Data is loading or no products match the selected criteria...</p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section >
    );
};

export default Tourpackegestours;
