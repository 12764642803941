import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Link, useNavigate } from 'react-router-dom'
import UserProfileCard from '../components/UserProfileCard';
import axios from 'axios';
import { deleteCab, fetchCabs } from '../redux/cabSlice';

const DashboardCabs = ({ loggedIn, adminLoggedIn, setLoggedIn, setAdminLoggedIn }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [message, setMessage] = useState(null); // State for messages

    const { cabs, loading, error } = useSelector(state => state.cabs)
    useEffect(() => {
        dispatch(fetchCabs());

    }, [dispatch]);

    console.log(cabs);

    const getImageSrc = (picture) => {
        // console.log(picture);

        if (!picture) {
            return 'assets/img/tours/t1.jpg'; // Default image
        }
        // Simple check to see if picture is a URL
        return picture.startsWith('http') ? picture : `${picture}`;
    };

    const onDeleteCab = async (productId) => {
        try {
            const response = await axios.delete(`/admin/api/deleteCabs/${productId}`);
            if (response.status === 200) {
                dispatch(fetchCabs())
                dispatch(deleteCab(productId)); // Update Redux store
                setMessage({ type: 'success', text: 'Cab deleted successfully' });
            } else {
                setMessage({ type: 'error', text: response.data.message || 'Failed to delete Cab' });
            }
        } catch (error) {
            setMessage({ type: 'error', text: 'Error deleting product' });
            console.error("Error deleting Cab:", error);
        }
    };
    const editTheCab = (id) => {
        navigate(`/update-cab/${id}`)
    }

    return (
        <main>
            <div className="p-top-90 p-bottom-90 bg-gray-gradient" >
                <section className="container">
                    <div className="d-lg-flex align-items-lg-end pb-4">
                        <div className="block-title me-auto">
                            <small className="sub-title">My Account</small>
                            <h1 className="display-5 title">Cabs</h1>
                        </div>
                        <div className="block-title me-auto">

                            <Link to="/addcabs">
                                <button className="btn btn-primary btn-uppercase w-100">
                                    <span> Add Cabs</span>
                                </button>                            </Link>
                        </div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mt-3">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Wishlist</li>
                            </ol>
                        </nav>
                    </div>
                </section>

                {message && (
                    <div className={`alert ${message.type === 'success' ? 'alert-success' : 'alert-danger'}`} role="alert">
                        {message.text}
                    </div>
                )}

                <section className="container">
                    <div className="row g-0 g-xl-8">
                        <div className="col-12 col-xl-9">
                            <div className="tour-grid pe-xl-4 me-xl-2">
                                <div className="row">
                                    {loading ? (
                                        <p>Loading...</p>
                                    ) : error ? (
                                        <p>{error}</p>
                                    ) : cabs.length > 0 ? (
                                        cabs.map((cab) => (
                                            <div className="col-12 col-xxl-4 col-md-6" key={cab._id}>
                                                <div className="tour-item rounded shadow-sm hover-effect mb-4">
                                                    <div className="tour-img">
                                                        <figure className="image-hover image-hover-overlay">
                                                            <img src={getImageSrc(cab.image[0].imageAddress)} alt={cab.carName} height={200} />
                                                            <i className="hicon hicon-plus-thin image-hover-icon"></i>
                                                        </figure>
                                                        <div className="tour-like">
                                                            <button type="button" className="circle-icon delete-icon" onClick={() => onDeleteCab(cab._id)}>
                                                                <i className="hicon hicon-x-icon hicon-70"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <div className="tour-content">
                                                        <h3 className="tour-title">
                                                            {cab.carName}</h3>
                                                        <div className="tour-itinerary">
                                                            <span><i className="hicon hicon-menu-calendar"></i>  {cab.carModel}</span>
                                                            <span><i className="hicon hicon-menu-calendar"></i>  A. C.</span>
                                                            <span><i className="hicon hicon-flights-pin"></i> {cab.seats} Seater</span>
                                                        </div>
                                                        <div className="inline-review">
                                                            <span className="review-score">{cab.rating}</span>
                                                            <span className="star-rate-view star-rate-size-sm me-2"><span className="star-value rate-50"></span></span>
                                                            <small className="review-total"><span>(231 reviews)</span></small>
                                                        </div>
                                                        <div className="tour-booking">

                                                            <button onClick={() => editTheCab(cab._id)} className="btn btn-primary btn-uppercase w-9">
                                                                Edit<i className="hicon hicon-flights-one-ways"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <p>No Cabs</p>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-xl-3">
                            <div className="card border-0 shadow-sm sticky-top sticky-top-120 d-none d-xl-block z-0">
                                <UserProfileCard adminLoggedIn={adminLoggedIn} loggedIn={loggedIn} setAdminLoggedIn={setAdminLoggedIn} setLoggedIn={setLoggedIn} />
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </main>)
}

export default DashboardCabs