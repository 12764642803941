import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
    name: 'user',
    initialState: {
        isLoggedIn: localStorage.getItem('isLoggedIn') === 'true',
        userData: JSON.parse(localStorage.getItem('userData')) || { cart: [], orders: [] }
    },
    reducers: {

        setUserData: (state, action) => {
            state.userData = action.payload;
            state.isLoggedIn = action.payload.isLoggedIn;
            localStorage.setItem('isLoggedIn', state.isLoggedIn.toString());
            localStorage.setItem('userData', JSON.stringify(state.userData));
        },
        addToCart: (state, action) => {
            state.userData.cart.push(action.payload);
            localStorage.setItem('userData', JSON.stringify(state.userData));
        },
        removeFromCart: (state, action) => {
            state.userData.cart = state.userData.cart.filter(id => id !== action.payload);
            localStorage.setItem('userData', JSON.stringify(state.userData));
        },
        addToOrders: (state, action) => {
            state.userData.orders.push(action.payload); // Add the new order
            localStorage.setItem('userData', JSON.stringify(state.userData));
        },
        removeFromOrders: (state, action) => {
            state.userData.orders = state.userData.orders.filter(id => id !== action.payload);
            localStorage.setItem('userData', JSON.stringify(state.userData));
        },
        logout: (state) => {
            state.isLoggedIn = false;
            state.userData = { cart: [], orders: [] }; // Reset user data on logout
            localStorage.removeItem('isLoggedIn');
            localStorage.removeItem('userData');
        },
    },
});

export const { setUserData, addToCart, removeFromCart, addToOrders, removeFromOrders, logout } = userSlice.actions;
export default userSlice.reducer;
