import React from 'react'
import Aboutustitle from '../components/Aboutustitle'
import Featured from "../components/Featured"
import Testimonials from '../components/Testimonials'
import AboutusAbout from '../components/AboutusAbout'
import AboutWhyChooseUs from '../components/AboutWhyChooseUs'
import WhatDriveUS from '../components/WhatDriveUS'

const Aboutus = () => {
    return (
        <main>
            <Aboutustitle />
            <Featured />
            <AboutusAbout />
            <AboutWhyChooseUs />
            <WhatDriveUS />
            <Testimonials />
        </main>
    )
}

export default Aboutus