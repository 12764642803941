import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import UserProfileCard from '../components/UserProfileCard'
import { useSelector, useDispatch } from 'react-redux';
import { fetchProducts } from '../redux/productSlice';
import { setUserData, removeFromCart } from '../redux/userSlice';

import axios from 'axios';



const Wishlist = ({ loggedIn, adminLoggedIn, setLoggedIn, setAdminLoggedIn }) => {

    const userData = useSelector((state) => state.user.userData);
    const { products } = useSelector((state) => state.product);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchProducts());
    }, [dispatch]);

    // if (!loading && !error && products.length > 0) {
    //     console.log("products in tour packages ", products);
    // }

    const getImageSrc = (picture) => {
        if (!picture) {
            return '../../assets/img/avatars/a3@2x.jpg'; // Default image
        }
        // Simple check to see if picture is a URL
        return picture.startsWith('http') ? picture : picture;
    };
    // console.log(userData.cart);
    const cartProducts = products.filter((product) => userData.cart.includes(product._id));

    // console.log(cartProducts);
    const onDeleteProduct = async (productId) => {
        try {
            const response = await axios.post("/user/api/unlikedProduct", { productId });
            console.log(response.data.message);

            // Assuming the API returns the updated userData
            if (response.data.userData) {
                dispatch(setUserData(response.data.userData));
            } else {
                // If the API doesn't return userData, manually update the cart
                dispatch(removeFromCart(productId));
            }
        } catch (error) {
            console.error("Error disliking the product:", error.response ? error.response.data.message : error.message);
        }
    };




    return (

        <main>
            <div className="p-top-90 p-bottom-90 bg-gray-gradient"  >

                {/* <!-- Title --> */}
                <section className="container">
                    <div className="d-lg-flex align-items-lg-end pb-4">
                        <div className="block-title me-auto">
                            <small className="sub-title">My Account</small>
                            <h1 className="display-5 title">Wishlist</h1>
                        </div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mt-3">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Wishlist</li>
                            </ol>
                        </nav>
                    </div>
                </section>
                {/* <!-- /Title --> */}

                {/* <!-- Wishlist --> */}
                <section className="container">
                    <div className="row g-0 g-xl-8">
                        <div className="col-12 col-xl-9">
                            {/* <!-- Tour list --> */}
                            <div class="tour-grid pe-xl-4 me-xl-2">
                                <div class="row">

                                    {
                                        cartProducts.length > 0 && cartProducts.map((product) => (
                                            <div className="col-12 col-xxl-4 col-md-6" key={product._id}>
                                                {/* <!-- Tour --> */}
                                                <div className="tour-item rounded shadow-sm hover-effect mb-4">
                                                    <div class="tour-img">
                                                        <Link to={`/tour-packages/${product.slug.split(' ').join("-").toLowerCase()}`} >
                                                            <figure className="image-hover image-hover-overlay ">
                                                                <img src={getImageSrc(product.image[0].imageAdress)} alt={product.name} />
                                                                <i className="hicon hicon-plus-thin image-hover-icon"></i>
                                                            </figure>
                                                        </Link>
                                                        <div class="tour-like">
                                                            <button type="button" class="circle-icon delete-icon" onClick={() => onDeleteProduct(product._id)}>
                                                                <i class="hicon hicon-x-icon hicon-70"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="tour-content">
                                                        <h3 className="tour-title">
                                                            <Link to={`/tour-packages/${product.slug.split(' ').join("-").toLowerCase()}`} className="link-dark link-hover">{product.name}</Link>
                                                        </h3>
                                                        <div className="tour-itinerary">
                                                            <span ><i className="hicon hicon-menu-calendar"></i>  {product.iteracy.curriculum.length} days</span>
                                                            <span><i className="hicon hicon-flights-pin"></i> {product.about.highlight.length} Destinations</span>
                                                        </div>
                                                        <div className="inline-review">
                                                            <span className="review-score">4.9</span>
                                                            <span className="star-rate-view star-rate-size-sm me-2"><span className="star-value rate-50"></span></span>
                                                            <small className="review-total"><span>(231 reviews)</span></small>
                                                        </div>
                                                        <div className="tour-booking">
                                                            <div className="tour-price">
                                                                <strong><sup>$</sup>{product.price}</strong>
                                                                <span ><sup>$</sup><del className="">{product.price + 150}</del></span>
                                                            </div>
                                                            <Link to={`/tour-packages/${product.slug.split(' ').join("-").toLowerCase()}`} className="circle-icon circle-icon-link">
                                                                <i className="hicon hicon-flights-one-ways"></i>
                                                            </Link>
                                                        </div>

                                                    </div>
                                                </div>
                                                {/* <!-- /Tour --> */}
                                            </div>
                                        ))
                                    }
                                </div>

                            </div>
                        </div>
                        {/* <!-- Tour list --> */}
                        <div className="col-12 col-xl-3">
                            {/* <!-- Account menu --> */}
                            <div className="card border-0 shadow-sm sticky-top sticky-top-120 d-none d-xl-block z-0">
                                <UserProfileCard adminLoggedIn={adminLoggedIn} loggedIn={loggedIn} setAdminLoggedIn={setAdminLoggedIn} setLoggedIn={setLoggedIn} />

                            </div>
                            {/* <!-- Account menu --> */}
                        </div>
                    </div>
                </section>
                {/* <!-- /Wishlist --> */}

            </div>
        </main>)
}

export default Wishlist