import React, { useEffect, useState } from 'react'
import UserProfileCard from '../components/UserProfileCard'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios';
import { useSelector } from 'react-redux'; // Import useSelector



const Setting = ({ loggedIn, adminLoggedIn }) => {

    const userData = useSelector((state) => state.user.userData);
    const adminData = useSelector((state) => state.admin.adminData);
    // console.log(adminData);

    useEffect(() => {

        // console.log(userData);
    }, [userData])


    const Country = [
        { "value": "AF", "name": "Afghanistan" },
        { "value": "AX", "name": "Åland Islands" },
        { "value": "AL", "name": "Albania" },
        { "value": "DZ", "name": "Algeria" },
        { "value": "AS", "name": "American Samoa" },
        { "value": "AD", "name": "Andorra" },
        { "value": "AO", "name": "Angola" },
        { "value": "AI", "name": "Anguilla" },
        { "value": "AG", "name": "Antigua and Barbuda" },
        { "value": "AR", "name": "Argentina" },
        { "value": "AM", "name": "Armenia" },
        { "value": "AW", "name": "Aruba" },
        { "value": "AU", "name": "Australia" },
        { "value": "AT", "name": "Austria" },
        { "value": "AZ", "name": "Azerbaijan" },
        { "value": "BS", "name": "Bahamas" },
        { "value": "BH", "name": "Bahrain" },
        { "value": "BD", "name": "Bangladesh" },
        { "value": "BB", "name": "Barbados" },
        { "value": "BY", "name": "Belarus" },
        { "value": "BE", "name": "Belgium" },
        { "value": "BZ", "name": "Belize" },
        { "value": "BJ", "name": "Benin" },
        { "value": "BM", "name": "Bermuda" },
        { "value": "BT", "name": "Bhutan" },
        { "value": "BO", "name": "Bolivia" },
        { "value": "BQ", "name": "Bonaire" },
        { "value": "BA", "name": "Bosnia and Herzegovina" },
        { "value": "BW", "name": "Botswana" },
        { "value": "BR", "name": "Brazil" },
        { "value": "IO", "name": "British Indian Ocean Territory" },
        { "value": "VG", "name": "British Virgin Islands" },
        { "value": "BN", "name": "Brunei" },
        { "value": "BG", "name": "Bulgaria" },
        { "value": "BF", "name": "Burkina Faso" },
        { "value": "BI", "name": "Burundi" },
        { "value": "KH", "name": "Cambodia" },
        { "value": "CM", "name": "Cameroon" },
        { "value": "CA", "name": "Canada" },
        { "value": "CV", "name": "Cape Verde" },
        { "value": "KY", "name": "Cayman Islands" },
        { "value": "CF", "name": "Central African Republic" },
        { "value": "TD", "name": "Chad" },
        { "value": "CL", "name": "Chile" },
        { "value": "CN", "name": "China" },
        { "value": "CX", "name": "Christmas Island" },
        { "value": "CC", "name": "Cocos Islands" },
        { "value": "CO", "name": "Colombia" },
        { "value": "KM", "name": "Comoros" },
        { "value": "CD", "name": "Congo-Brazzaville" },
        { "value": "CK", "name": "Cook Islands" },
        { "value": "CR", "name": "Costa Rica" },
        { "value": "CI", "name": "Côte d'Ivoire" },
        { "value": "HR", "name": "Croatia" },
        { "value": "CW", "name": "Curaçao" },
        { "value": "CY", "name": "Cyprus" },
        { "value": "CZ", "name": "Czech Republic" },
        { "value": "DK", "name": "Denmark" },
        { "value": "DJ", "name": "Djibouti" },
        { "value": "DM", "name": "Dominica" },
        { "value": "DO", "name": "Dominican Republic" },
        { "value": "EC", "name": "Ecuador" },
        { "value": "EG", "name": "Egypt" },
        { "value": "SV", "name": "El Salvador" },
        { "value": "GQ", "name": "Equatorial Guinea" },
        { "value": "ER", "name": "Eritrea" },
        { "value": "EE", "name": "Estonia" },
        { "value": "ET", "name": "Ethiopia" },
        { "value": "FK", "name": "Falkland Islands" },
        { "value": "FO", "name": "Faroe Islands" },
        { "value": "FJ", "name": "Fiji" },
        { "value": "FI", "name": "Finland" },
        { "value": "FR", "name": "France" },
        { "value": "GF", "name": "French Guiana" },
        { "value": "PF", "name": "French Polynesia" },
        { "value": "GA", "name": "Gabon" },
        { "value": "GM", "name": "Gambia" },
        { "value": "GE", "name": "Georgia" },
        { "value": "DE", "name": "Germany" },
        { "value": "GH", "name": "Ghana" },
        { "value": "GI", "name": "Gibraltar" },
        { "value": "GR", "name": "Greece" },
        { "value": "GL", "name": "Greenland" },
        { "value": "GD", "name": "Grenada" },
        { "value": "GP", "name": "Guadeloupe" },
        { "value": "GU", "name": "Guam" },
        { "value": "GT", "name": "Guatemala" },
        { "value": "GG", "name": "Guernsey" },
        { "value": "GN", "name": "Guinea" },
        { "value": "GW", "name": "Guinea-Bissau" },
        { "value": "GY", "name": "Guyana" },
        { "value": "HT", "name": "Haiti" },
        { "value": "HN", "name": "Honduras" },
        { "value": "HK", "name": "Hong Kong" },
        { "value": "HU", "name": "Hungary" },
        { "value": "IS", "name": "Iceland" },
        { "value": "IN", "name": "India" },
        { "value": "ID", "name": "Indonesia" },
        { "value": "IQ", "name": "Iraq" },
        { "value": "IE", "name": "Ireland" },
        { "value": "IM", "name": "Isle of Man" },
        { "value": "IL", "name": "Israel" },
        { "value": "IT", "name": "Italy" },
        { "value": "JM", "name": "Jamaica" },
        { "value": "JP", "name": "Japan" },
        { "value": "JE", "name": "Jersey" },
        { "value": "JO", "name": "Jordan" },
        { "value": "KZ", "name": "Kazakhstan" },
        { "value": "KE", "name": "Kenya" },
        { "value": "KI", "name": "Kiribati" },
        { "value": "XK", "name": "Kosovo" },
        { "value": "KW", "name": "Kuwait" },
        { "value": "KG", "name": "Kyrgyz Republic" },
        { "value": "LA", "name": "Laos" },
        { "value": "SS", "name": "Laos" }, // This entry is duplicated, consider removing or correcting
        { "value": "LV", "name": "Latvia" },
        { "value": "LB", "name": "Lebanon" },
        { "value": "LS", "name": "Lesotho" },
        { "value": "LR", "name": "Liberia" },
        { "value": "LY", "name": "Libya" },
        { "value": "LI", "name": "Liechtenstein" },
        { "value": "LT", "name": "Lithuania" },
        { "value": "LU", "name": "Luxembourg" },
        { "value": "MO", "name": "Macao" },
        { "value": "MG", "name": "Madagascar" },
        { "value": "MW", "name": "Malawi" },
        { "value": "MY", "name": "Malaysia" },
        { "value": "MV", "name": "Maldives" },
        { "value": "ML", "name": "Mali" },
        { "value": "MT", "name": "Malta" },
        { "value": "MH", "name": "Marshall Islands" },
        { "value": "MQ", "name": "Martinique" },
        { "value": "MR", "name": "Mauritania" },
        { "value": "MU", "name": "Mauritius" },
        { "value": "YT", "name": "Mayotte" },
        { "value": "MX", "name": "Mexico" },
        { "value": "FM", "name": "Micronesia" },
        { "value": "MD", "name": "Moldova" },
        { "value": "MC", "name": "Monaco" },
        { "value": "MN", "name": "Mongolia" },
        { "value": "ME", "name": "Montenegro" },
        { "value": "MS", "name": "Montserrat" },
        { "value": "MA", "name": "Morocco" },
        { "value": "MZ", "name": "Mozambique" },
        { "value": "MM", "name": "Myanmar" },
        { "value": "NA", "name": "Namibia" },
        { "value": "NR", "name": "Nauru" },
        { "value": "NP", "name": "Nepal" },
        { "value": "NL", "name": "Netherlands" },
        { "value": "NC", "name": "New Caledonia" },
        { "value": "NZ", "name": "New Zealand" },
        { "value": "NI", "name": "Nicaragua" },
        { "value": "NE", "name": "Niger" },
        { "value": "NG", "name": "Nigeria" },
        { "value": "NU", "name": "Niue" },
        { "value": "NF", "name": "Norfolk Island" },
        { "value": "MK", "name": "North Macedonia" },
        { "value": "MP", "name": "Northern Mariana Islands" },
        { "value": "NO", "name": "Norway" },
        { "value": "OM", "name": "Oman" },
        { "value": "PK", "name": "Pakistan" },
        { "value": "PW", "name": "Palau" },
        { "value": "PS", "name": "Palestine" },
        { "value": "PA", "name": "Panama" },
        { "value": "PG", "name": "Papua New Guinea" },
        { "value": "PY", "name": "Paraguay" },
        { "value": "PE", "name": "Peru" },
        { "value": "PH", "name": "Philippines" },
        { "value": "PL", "name": "Poland" },
        { "value": "PT", "name": "Portugal" },
        { "value": "PR", "name": "Puerto Rico" },
        { "value": "QA", "name": "Qatar" },
        { "value": "CG", "name": "Republic of the Congo" },
        { "value": "RE", "name": "Réunion" },
        { "value": "RO", "name": "Romania" },
        { "value": "RU", "name": "Russia" },
        { "value": "RW", "name": "Rwanda" },
        { "value": "BL", "name": "Saint Barthélemy" },
        { "value": "SH", "name": "Saint Helena" },
        { "value": "KN", "name": "Saint Kitts and Nevis" },
        { "value": "LC", "name": "Saint Lucia" },
        { "value": "MF", "name": "Saint Martin" },
        { "value": "PM", "name": "Saint Pierre and Miquelon" },
        { "value": "VC", "name": "Saint Vincent and the Grenadines" },
        { "value": "WS", "name": "Samoa" },
        { "value": "SM", "name": "San Marino" },
        { "value": "ST", "name": "São Tomé and Príncipe" },
        { "value": "SA", "name": "Saudi Arabia" },
        { "value": "SN", "name": "Senegal" },
        { "value": "RS", "name": "Serbia" },
        { "value": "SC", "name": "Seychelles" },
        { "value": "SL", "name": "Sierra Leone" },
        { "value": "SG", "name": "Singapore" },
        { "value": "SX", "name": "Sint Maarten" },
        { "value": "SK", "name": "Slovakia" },
        { "value": "SI", "name": "Slovenia" },
        { "value": "SB", "name": "Solomon Islands" },
        { "value": "SO", "name": "Somalia" },
        { "value": "ZA", "name": "South Africa" },
        { "value": "KR", "name": "South Korea" },
        { "value": "ES", "name": "Spain" },
        { "value": "LK", "name": "Sri Lanka" },
        { "value": "SD", "name": "Sudan" },
        { "value": "SR", "name": "Suriname" },
        { "value": "SJ", "name": "Svalbard and Jan Mayen" },
        { "value": "SZ", "name": "Swaziland" },
        { "value": "SE", "name": "Sweden" },
        { "value": "CH", "name": "Switzerland" },
        { "value": "TW", "name": "Taiwan" },
        { "value": "TJ", "name": "Tajikistan" },
        { "value": "TZ", "name": "Tanzania" },
        { "value": "TH", "name": "Thailand" },
        { "value": "TL", "name": "Timor-Leste" },
        { "value": "TG", "name": "Togo" },
        { "value": "TK", "name": "Tokelau" },
        { "value": "TO", "name": "Tonga" },
        { "value": "TT", "name": "Trinidad and Tobago" },
        { "value": "TN", "name": "Tunisia" },
        { "value": "TR", "name": "Turkey" },
        { "value": "TM", "name": "Turkmenistan" },
        { "value": "TC", "name": "Turks and Caicos Islands" },
        { "value": "TV", "name": "Tuvalu" },
        { "value": "UG", "name": "Uganda" },
        { "value": "UA", "name": "Ukraine" },
        { "value": "AE", "name": "United Arab Emirates" },
        { "value": "GB", "name": "United Kingdom" },
        { "value": "US", "name": "United States" },
        { "value": "UY", "name": "Uruguay" },
        { "value": "VI", "name": "US Virgin Islands" },
        { "value": "UZ", "name": "Uzbekistan" },
        { "value": "VU", "name": "Vanuatu" },
        { "value": "VE", "name": "Venezuela" },
        { "value": "VN", "name": "Vietnam" },
        { "value": "WF", "name": "Wallis and Futuna" },
        { "value": "EH", "name": "Western Sahara" },
        { "value": "YE", "name": "Yemen" },
        { "value": "ZM", "name": "Zambia" },
        { "value": "ZW", "name": "Zimbabwe" }
    ]


    const [errorMessage, setErrorMessage] = useState("");

    const [gender, setGender] = useState(adminLoggedIn ? adminData.gender : userData.gender); // Default to empty string
    const [birthday, setBirthday] = useState(adminLoggedIn ? adminData.gender : userData.birthday);
    const [firstName, setFirstName] = useState(adminLoggedIn ? adminData.fullname.split(" ")[0] : userData.fullname.split(" ")[0]);
    const [lastName, setLastName] = useState(adminLoggedIn ? adminData.fullname.split(" ").slice(1).join(" ") : userData.fullname.split(" ").slice(1).join(" "));
    const [phone, setPhone] = useState(adminLoggedIn ? adminData.phone : userData.phone);
    const [email, setEmail] = useState(adminLoggedIn ? adminData.email : userData.email);
    const [address, setAddress] = useState(adminLoggedIn ? adminData.address : userData.address);
    const [city, setCity] = useState(adminLoggedIn ? adminData.city : userData.city);
    const [postCode, setPostCode] = useState(adminLoggedIn ? adminData.postCode : userData.postCode);
    const [country, setCountry] = useState(adminLoggedIn ? adminData.country : userData.country);
    const [profileImageUrl, setProfileImageUrl] = useState('');
    const navigate = useNavigate();


    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append("gender", gender);
        formData.append("birthday", birthday);
        formData.append("firstName", firstName);
        formData.append("lastName", lastName);
        formData.append("phone", phone);
        formData.append("email", email);
        formData.append("address", address);
        formData.append("city", city);
        formData.append("postCode", postCode);
        formData.append("country", country);
        formData.append("profileImageUrl", profileImageUrl); // Append the file object

        try {
            if (loggedIn) {
                // Update for a logged-in user
                await axios.post('/user/api/updateUser', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
            } else {
                // Update for an admin
                await axios.post('/admin/api/updateAdmin', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
            }

            // Handle success
            // Clear fields and navigate as before
            resetFormFields();
            navigate('/setting');

        } catch (error) {
            if (error.response && error.response.status === 409) {
                setErrorMessage("A conflict occurred during the update.");
            } else {
                setErrorMessage("There was an error updating the information!");
            }
            console.error("There was an error updating the information!", error);
        }
    };


    // Utility function to reset form fields
    const resetFormFields = () => {
        setGender("");
        setBirthday("");
        setFirstName("");
        setLastName("");
        setPhone("");
        setEmail("");
        setAddress("");
        setCity("");
        setPostCode("");
        setCountry("");
        setProfileImageUrl("");
    };




    return (
        <main>
            <div className="p-top-90 p-bottom-90 bg-gray-gradient">
                {/* <!-- Title --> */}
                <section className="container">
                    <div className="d-lg-flex align-items-lg-end pb-4">
                        <div className="block-title me-auto">
                            <small className="sub-title">My Account</small>
                            <h1 className="display-5 title">Settings</h1>
                        </div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mt-3">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Settings</li>
                            </ol>
                        </nav>
                    </div>
                </section>
                {/* <!-- /Title --> */}

                {/* <!-- Settings --> */}
                <section className="container">
                    <div className="row g-0 g-xl-8">
                        <div className="col-12 col-xl-9">
                            {/* <!-- Your Profile --> */}
                            <div className="pe-xl-4 me-xl-2">
                                <div className="card border-0 shadow-sm">
                                    <div className="card-body">
                                        <div className="d-flex align-items-center justify-content-between border-bottom pb-4 mb-4">
                                            <h2 className="h3 text-body-emphasis mb-0">Your profile</h2>
                                        </div>
                                        {errorMessage && (
                                            <div className="alert alert-danger">
                                                {errorMessage}
                                            </div>
                                        )}
                                        <form method="post" onSubmit={handleSubmit} autoComplete="off" encType="multipart/form-data">
                                            <div className="row">
                                                <div className="col-12 col-md-6">
                                                    <div className="mb-4">
                                                        <label className="form-label" htmlFor="selGender">Gender<span className="text-danger">*</span></label>
                                                        <select
                                                            className="form-select dropdown-select shadow-sm"
                                                            id="selGender"

                                                            value={gender}
                                                            onChange={(e) => setGender(e.target.value)}
                                                        >
                                                            <option value="">Select</option>
                                                            <option value="Male">Male</option>
                                                            <option value="Female">Female</option>
                                                            <option value="Unknown">Unknown</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6">
                                                    <div className="mb-4">
                                                        <label className="form-label" htmlFor="txtBirthday">Birthday<span className="text-danger">*</span></label>
                                                        <div className="input-icon-group date-of-birth">
                                                            <label className="input-icon hicon hicon-menu-calendar" htmlFor="txtBirthday"></label>
                                                            <input
                                                                id="txtBirthday"
                                                                type="date"
                                                                className="form-control shadow-sm"
                                                                value={birthday}
                                                                onChange={(e) => setBirthday(e.target.value)}

                                                                autoComplete="off"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 col-md-6">
                                                    <div className="mb-4">
                                                        <label className="form-label" htmlFor="Imagephoto">Profile Photo<span className="text-danger">*</span></label>
                                                        <input
                                                            type="file"
                                                            className="form-control shadow-sm"
                                                            accept="image/*" // Only allow image files
                                                            onChange={(e) => setProfileImageUrl(e.target.files[0])}
                                                            id="profileimageurl"

                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6">
                                                    <div className="mb-4">
                                                        <label className="form-label" htmlFor="txtFirstname">First Name<span className="text-danger">*</span></label>
                                                        <input
                                                            type="text"
                                                            className="form-control shadow-sm"
                                                            value={firstName}
                                                            onChange={(e) => setFirstName(e.target.value)}
                                                            id="txtFirstname"

                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6">
                                                    <div className="mb-4">
                                                        <label className="form-label" htmlFor="txtLastname">Last Name<span className="text-danger">*</span></label>
                                                        <input
                                                            type="text"
                                                            className="form-control shadow-sm"
                                                            value={lastName}
                                                            onChange={(e) => setLastName(e.target.value)}
                                                            id="txtLastname"

                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6">
                                                    <div className="mb-4">
                                                        <label className="form-label" htmlFor="txtPhone">Phone<span className="text-danger">*</span></label>
                                                        <input
                                                            type="text"
                                                            className="form-control shadow-sm"
                                                            value={phone}
                                                            onChange={(e) => setPhone(e.target.value)}
                                                            id="txtPhone"

                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6">
                                                    <div className="mb-4">
                                                        <label className="form-label" htmlFor="txtEmail">Email<span className="text-danger">*</span></label>
                                                        <input
                                                            type="email"
                                                            className="form-control shadow-sm"
                                                            value={email}
                                                            onChange={(e) => setEmail(e.target.value)}
                                                            id="txtEmail"

                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6">
                                                    <div className="mb-4">
                                                        <label className="form-label" htmlFor="txtAddress">Address<span className="text-danger">*</span></label>
                                                        <input
                                                            type="text"
                                                            className="form-control shadow-sm"
                                                            value={address}
                                                            onChange={(e) => setAddress(e.target.value)}
                                                            id="txtAddress"

                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6">
                                                    <div className="mb-4">
                                                        <label className="form-label" htmlFor="txtCity">City<span className="text-danger">*</span></label>
                                                        <input
                                                            type="text"
                                                            className="form-control shadow-sm"
                                                            value={city}
                                                            onChange={(e) => setCity(e.target.value)}
                                                            id="txtCity"

                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6">
                                                    <div className="mb-4">
                                                        <label className="form-label" htmlFor="txtPostCode">Post Code<span className="text-danger">*</span></label>
                                                        <input
                                                            type="text"
                                                            className="form-control shadow-sm"
                                                            value={postCode}
                                                            onChange={(e) => setPostCode(e.target.value)}
                                                            id="txtPostCode"

                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6">
                                                    <div className="mb-4">
                                                        <label className="form-label" htmlFor="selCountry">Country<span className="text-danger">*</span></label>
                                                        <select
                                                            className="form-select dropdown-select shadow-sm"
                                                            id="selCountry"

                                                            value={country}
                                                            onChange={(e) => setCountry(e.target.value)}
                                                        >
                                                            {Country.map((data) => (
                                                                <option key={data.value} value={data.name}>
                                                                    {data.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="border-top pt-4">
                                                <button type="submit" className="btn btn-primary mnw-180">
                                                    <i className="hicon hicon-check-valid-state"></i>
                                                    Update profile
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- /Your Profile --> */}
                        </div>
                        <div className="col-12 col-xl-3">
                            {/* <!-- Account menu --> */}
                            <div className="card border-0 shadow-sm sticky-top sticky-top-120 d-none d-xl-block z-0">
                                <UserProfileCard adminLoggedIn={adminLoggedIn} loggedIn={loggedIn} />
                            </div>
                            {/* <!-- Account menu --> */}
                        </div>
                    </div>
                </section>
                {/* <!-- /Settings --> */}
            </div>
        </main>


    )
}

export default Setting