import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProducts } from '../redux/productSlice';
import { setUserData, addToCart, removeFromCart } from '../redux/userSlice';
import axios from 'axios';
const SingleDestinationPackage = ({ destination }) => {

    const [errorMessage, setErrorMessage] = useState(""); // State to store error message
    const [successMessage, setSuccessMessage] = useState("");
    const loggedIn = useSelector((state) => state.user.isLoggedIn);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userData = useSelector((state) => state.user.userData);
    const [likedProducts, setLikedProducts] = useState(userData?.cart || []);
    const { products, loading, error } = useSelector((state) => state.product);

    useEffect(() => {
        dispatch(fetchProducts())
    }, [dispatch])

    console.log(products);

    const filteredProduct = products.filter((product) => destination.products.includes(product._id));

    // console.log("filteredproduct", filteredProduct);


    const getImageSrc = (picture) => {
        if (!picture) {
            return '/assets/img/avatars/a3@2x.jpg'; // Default image
        }
        return picture.startsWith('http') ? picture : picture;
    };

    const onLikeProduct = async (productId) => {
        if (!loggedIn) {
            navigate("/login");
            return;
        }

        try {
            const response = await axios.post("/user/api/likedProduct", { productId });
            // console.log(response.data.message);
            document.getElementById(`likeButton${productId}`).style.color = "#5143d9"

            if (response.data.userData) {
                dispatch(setUserData(response.data.userData));
                setSuccessMessage("Product liked and added to your wishlist successfully!");
                setErrorMessage("");
            } else {
                dispatch(addToCart(productId));
                setSuccessMessage("Product liked and added to your wishlist successfully!");
                setErrorMessage("");
            }
        } catch (error) {
            // console.error("Error liking the product:", error.response ? error.response.data.message : error.message);
            if (error.response) {
                if (error.response.status === 409) {
                    // Handle the case where the product is already liked
                    try {
                        const response = await axios.post("/user/api/unlikedProduct", { productId });
                        // console.log(response.data.message);
                        setLikedProducts((prev) => prev.filter((id) => id !== productId)); // Remove from liked products
                        document.getElementById(`likeButton${productId}`).style.color = "grey"

                        document.getElementById(`likeButton${productId}`).classList.remove("liked");
                        // Assuming the API returns the updated userData
                        if (response.data.userData) {
                            dispatch(setUserData(response.data.userData));
                            setSuccessMessage("Product removed from your wishlist successfully!");
                        } else {
                            // If the API doesn't return userData, manually update the cart
                            dispatch(removeFromCart(productId));
                            setSuccessMessage("Product removed from your wishlist successfully!");
                        }
                    } catch (error) {
                        console.error("Error disliking the product:", error.response ? error.response.data.message : error.message);
                    }
                } else {
                    // Handle other errors
                    setErrorMessage(error.response.data.message);
                    setSuccessMessage("");
                }
            } else {
                setErrorMessage(error.message); // Handle network or other errors
                setSuccessMessage("");
            }

            console.error("Error liking the product:", errorMessage);
        }
    };

    return (
        <section className="p-top-90 p-bottom-90 bg-gray-gradient">
            <div className="container">
                <div className="mb-4">
                    <div className="row g-0 g-md-2 align-items-end">
                        <div className="col-12 col-xl-4">
                            {/* <!-- Title --> */}
                            <div className="block-title me-auto">
                                <small className="sub-title">   Explore {destination.name}</small>
                                <h2 className="h1 title">Tours packages in {destination.name}</h2>
                            </div>
                            {/* <!-- /Title --> */}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="tour-grid mb-5 mb-xl-0">
                        <div className="row">
                            {successMessage && (
                                <div className="alert alert-success">
                                    {successMessage}
                                </div>
                            )}
                            {errorMessage && (
                                <div className="alert alert-danger">
                                    {errorMessage}
                                </div>
                            )}
                            {!error && !loading && products.length > 0 ? (
                                filteredProduct.map((product) => (
                                    <div className="col-12 col-xxl-3 col-md-6" key={product._id}>
                                        {/* Tour */}
                                        <div className="tour-item rounded shadow-sm hover-effect mb-4">
                                            <div className="tour-img">
                                                <Link to={`/tour-packages/${product.slug.split(' ').join("-").toLowerCase()}`} >
                                                    <figure className="image-hover image-hover-overlay">
                                                        <img
                                                            src={`/${getImageSrc(product.image[0].imageAddress)}`}
                                                            alt={product.name}
                                                            height={200}
                                                            style={{ objectFit: "cover" }}
                                                        />
                                                        <i className="hicon hicon-plus-thin image-hover-icon"></i>
                                                    </figure>
                                                </Link>
                                                <div className="tour-like">
                                                    <button
                                                        onClick={() => onLikeProduct(product._id)}
                                                        type="button"
                                                        id={`likeButton${product._id}`}
                                                        className={`circle-icon like-icon ${likedProducts.includes(product._id) ? "liked" : ""}`}

                                                    >
                                                        <i className="hicon hicon-favorite-filled"></i>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="tour-content">
                                                <h3 className="tour-title">
                                                    <Link to={`/tour-packages/${product.slug.split(' ').join("-").toLowerCase()}`} state={{ product, products }} >
                                                        {product.name}
                                                    </Link>
                                                </h3>
                                                <div className="tour-itinerary">
                                                    <span>
                                                        <i className="hicon hicon-menu-calendar"></i>{` ${product.iteracy?.curriculum.length} days`}</span>

                                                    <span>
                                                        <i className="hicon hicon-flights-pin"></i>
                                                        {` ${product.about.highlight.length} Destinations`}
                                                    </span>
                                                </div>
                                                <div className="inline-review mb-4">
                                                    <span className="review-score">{product.rating}</span>
                                                    <span className="star-rate-view star-rate-size-sm me-2">
                                                        <span className="star-value rate-50"></span>
                                                    </span>
                                                    <small className="review-total">
                                                        <span>(231 reviews)</span>
                                                    </small>
                                                </div>
                                                <div className="tour-booking d-flex align-items-center">
                                                    <div className="tour-price me-auto">
                                                        <strong>
                                                            <sup>₹</sup>
                                                            {product.price}
                                                        </strong>
                                                        <span>
                                                            <sup>₹</sup>
                                                            <del>500.00</del>
                                                        </span>
                                                    </div>
                                                    <Link to={`/tour-packages/${product.slug.split(' ').join("-").toLowerCase()}`} state={{ product, products }} className="circle-icon circle-icon-link">
                                                        <i className="hicon hicon-flights-one-ways"></i>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                        {/* /Tour */}
                                    </div>

                                ))
                            ) : (
                                <p>Data is loading or no products match the selected criteria...</p>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </section >)
}

export default SingleDestinationPackage