import React from 'react';
import { Link } from 'react-router-dom';

const Tourpackegestitle = ({ destination, packages }) => {
    // Set fallback value for destination name
    const destinationName = destination?.name || "TripNow"; // Fallback to "tripnow"


    return (
        <section className="hero">
            <div className="hero-bg">
                <img
                    src="./assets/img/destinations/tripnow-destinations.avif"
                    srcSet="./assets/img/destinations/tripnow-destinations.avif 2x"
                    alt="Destination"
                />
            </div>
            <div className="bg-content container">
                <div className="hero-page-title">
                    <span className="hero-sub-title">Hidden Charm</span>
                    <h1 className="display-3 hero-title">
                        Explore {destinationName} {/* Use the fallback value here */}
                    </h1>
                </div>
                <nav aria-label="breadcrumb">
                    {
                        destination ?
                            <ol className="breadcrumb mb-0">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item"><Link to="/destinations">Destinations</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">{destinationName}</li>
                            </ol> :
                            <ol className="breadcrumb mb-0">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Tour packages in {destinationName}</li>
                            </ol>
                    }
                </nav>
                <div className="title-info">
                    <div className="title-info-body">
                        <div className="row justify-content-end">
                            <div className="col-12 col-xxl-3 col-xl-4 col-md-6">
                                <div className="horizontal-review rounded-top ms-xl-2">
                                    <h2 className="h6 mb-2 ms-1">
                                        <i className="hicon hicon-bold hicon-flights-pin"></i>
                                        Attractive destination
                                    </h2>
                                    <div className="review-content">
                                        <h3 className="review-score">4.9</h3>
                                        <div className="review-total">
                                            <span className="star-rate-view">
                                                <span className="star-value rate-50"></span>
                                            </span>
                                            <span>
                                                <strong className="text-body">2,394</strong> reviews
                                            </span>
                                        </div>
                                    </div>
                                    <div className="review-label">
                                        <small>Excellent</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Tourpackegestitle;
