import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Link, useNavigate } from 'react-router-dom'
import UserProfileCard from '../components/UserProfileCard';
import axios from 'axios';
import { deleteHotel, fetchHotels } from '../redux/hotelSclice';

const DashboardHotel = ({ loggedIn, adminLoggedIn, setLoggedIn, setAdminLoggedIn }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [message, setMessage] = useState(null); // State for messages

    const { hotels, loading, error } = useSelector(state => state.hotels)
    useEffect(() => {
        dispatch(fetchHotels());

    }, [dispatch]);

    console.log(hotels);

    const getImageSrc = (picture) => {
        // console.log(picture);

        if (!picture) {
            return 'assets/img/tours/t1.jpg'; // Default image
        }
        // Simple check to see if picture is a URL
        return picture.startsWith('http') ? picture : `/images/hotelImage/${picture}`;
    };

    const onDeleteHotel = async (productId) => {
        try {
            const response = await axios.delete(`/admin/api/deletehotel/${productId}`);
            if (response.status === 200) {
                dispatch(fetchHotels())
                dispatch(deleteHotel(productId)); // Update Redux store
                setMessage({ type: 'success', text: 'Hotel deleted successfully' });
            } else {
                setMessage({ type: 'error', text: response.data.message || 'Failed to delete hotel' });
            }
        } catch (error) {
            setMessage({ type: 'error', text: 'Error deleting product' });
            console.error("Error deleting hotel:", error);
        }
    };
    const editThehotel = (id) => {
        navigate(`/update-hotel/${id.split(" ").join("-").toLowerCase()}`)
    }

    return (
        <main>
            <div className="p-top-90 p-bottom-90 bg-gray-gradient" >
                <section className="container">
                    <div className="d-lg-flex align-items-lg-end pb-4">
                        <div className="block-title me-auto">
                            <small className="sub-title">My Account</small>
                            <h1 className="display-5 title">hotels</h1>
                        </div>
                        <div className="block-title me-auto">

                            <Link to="/addhotel">
                                <button className="btn btn-primary btn-uppercase w-100">
                                    <span> Add Hotel</span>
                                </button>                            </Link>
                        </div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mt-3">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Wishlist</li>
                            </ol>
                        </nav>
                    </div>
                </section>

                {message && (
                    <div className={`alert ${message.type === 'success' ? 'alert-success' : 'alert-danger'}`} role="alert">
                        {message.text}
                    </div>
                )}

                <section className="container">
                    <div className="row g-0 g-xl-8">
                        <div className="col-12 col-xl-9">
                            <div className="tour-grid pe-xl-4 me-xl-2">
                                <div className="row">
                                    {loading ? (
                                        <p>Loading...</p>
                                    ) : error ? (
                                        <p>{error}</p>
                                    ) : hotels.length > 0 ? (
                                        hotels.map((hotel) => (
                                            <div className="col-12 col-xxl-4 col-md-6" key={hotel._id}>
                                                <div className="tour-item rounded shadow-sm hover-effect mb-4">
                                                    <div className="tour-img">
                                                        <Link to={`/hotel/${hotel.slug.split(' ').join("-").toLowerCase()}`} >
                                                            <figure className="image-hover image-hover-overlay">
                                                                <img src={getImageSrc(hotel.image[0].imageAdress)} alt={hotel.name} />
                                                                <i className="hicon hicon-plus-thin image-hover-icon"></i>
                                                            </figure>
                                                        </Link>
                                                        <div className="tour-like">
                                                            <button type="button" className="circle-icon delete-icon" onClick={() => onDeleteHotel(hotel._id)}>
                                                                <i className="hicon hicon-x-icon hicon-70"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="tour-content">
                                                        <h3 className="tour-title">
                                                            <Link to={`/hotel/${hotel.slug.split(' ').join("-").toLowerCase()}`} className="link-dark link-hover">
                                                                {hotel.name}
                                                            </Link>
                                                        </h3>
                                                        <div className="tour-itinerary">
                                                            <span><i className="hicon hicon-menu-calendar"></i>  3 days</span>
                                                            <span><i className="hicon hicon-flights-pin"></i> 3 Destinations</span>
                                                        </div>
                                                        <div className="inline-review">
                                                            <span className="review-score">4.9</span>
                                                            <span className="star-rate-view star-rate-size-sm me-2"><span className="star-value rate-50"></span></span>
                                                            <small className="review-total"><span>(231 reviews)</span></small>
                                                        </div>
                                                        <div className="tour-booking">
                                                            <div className="tour-price">
                                                                <strong><sup>$</sup>{hotel.price}</strong>
                                                                <span><sup>$</sup><del>{hotel.price + 150}</del></span>
                                                            </div>
                                                            <button onClick={() => editThehotel(hotel.slug)} className="btn btn-primary btn-uppercase w-9">
                                                                Edit<i className="hicon hicon-flights-one-ways"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <p>No hotels</p>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-xl-3">
                            <div className="card border-0 shadow-sm sticky-top sticky-top-120 d-none d-xl-block z-0">
                                <UserProfileCard adminLoggedIn={adminLoggedIn} loggedIn={loggedIn} setAdminLoggedIn={setAdminLoggedIn} setLoggedIn={setLoggedIn} />
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </main>)
}

export default DashboardHotel