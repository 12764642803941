import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Async thunk for fetching cab data
export const fetchCabs = createAsyncThunk('cab/fetchCabs', async () => {
    console.log("fetchCabs action called...");
    const response = await axios.get('/api/cabData');
    return response.data;  // Assuming the backend returns the cabs array
});

const initialState = {
    cabs: [],  // Array to hold cab data
    selectedCab: null,  // To hold a specific cab when selected
    loading: false,  // Loading state for API calls
    error: null,  // Error state in case API call fails
};

const cabSlice = createSlice({
    name: 'cabs',
    initialState,
    reducers: {
        addCab: (state, action) => {
            state.cabs.push(action.payload);
        },
        updateCab: (state, action) => {
            const index = state.cabs.findIndex(cab => cab.id === action.payload.id);
            if (index !== -1) {
                state.cabs[index] = action.payload;
            }
        },
        deleteCab: (state, action) => {
            state.cabs = state.cabs.filter(cab => cab.id !== action.payload);
        },
        selectCab: (state, action) => {
            state.selectedCab = state.cabs.find(cab => cab.id === action.payload);
        },
        clearSelectedCab: (state) => {
            state.selectedCab = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCabs.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchCabs.fulfilled, (state, action) => {
                state.cabs = action.payload;
                state.loading = false;
            })
            .addCase(fetchCabs.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export const {
    addCab,
    updateCab,
    deleteCab,
    selectCab,
    clearSelectedCab,
} = cabSlice.actions;

export default cabSlice.reducer;
