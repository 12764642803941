import React from 'react'


const PrivacyPolicy = () => {
    return (
        <section className="p-top-90 p-bottom-90 bg-gray-gradient">

            <div className="container">
                <div className="mb-4">
                    <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
                        <h1>Privacy Policy</h1>
                        <p><strong>Effective Date: 20-11-2024</strong></p>
                        <p>At TripNow, we value your trust and are committed to protecting your personal information. This Privacy Policy explains how we collect, use, disclose, and safeguard your information.</p>

                        <h2>1. Information We Collect</h2>
                        <p><strong>Personal Information:</strong> Name, contact details, email, and payment information when you book our services.</p>
                        <p><strong>Non-Personal Information:</strong> Browser type, IP address, and browsing behavior for improving website performance.</p>
                        <p><strong>Cookies:</strong> We use cookies to enhance your user experience.</p>

                        <h2>2. How We Use Your Information</h2>
                        <ul>
                            <li>To process bookings, payments, and itineraries.</li>
                            <li>To communicate about offers, updates, and promotions.</li>
                            <li>To analyze website performance and improve user experience.</li>
                        </ul>

                        <h2>3. Data Sharing</h2>
                        <p>We share data with trusted third parties (payment processors, travel vendors) to fulfill your bookings.</p>
                        <p>Your data is not sold or shared with unauthorized third parties.</p>

                        <h2>4. Data Security</h2>
                        <p>We implement SSL encryption, secure servers, and restricted access to protect your data.</p>

                        <h2>5. Your Rights</h2>
                        <ul>
                            <li>Access and update your personal information.</li>
                            <li>Opt-out of marketing emails.</li>
                            <li>Request deletion of your data, as permitted by law.</li>
                        </ul>

                        <h2>6. Updates to This Policy</h2>
                        <p>We may update this Privacy Policy from time to time. Please review it periodically for changes.</p>

                        <p>For questions, email us at <a href="mailto:tripnow.cc@gmail.com">tripnow.cc@gmail.com</a>.</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PrivacyPolicy