import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Link } from 'react-router-dom'
import { fetchCabs } from '../redux/cabSlice';

const CabsCards = () => {

    const dispatch = useDispatch();

    const { cabs, loading, error } = useSelector(state => state.cabs)
    useEffect(() => {
        dispatch(fetchCabs());

    }, [dispatch]);

    console.log(cabs);

    const getImageSrc = (picture) => {
        // console.log(picture);

        if (!picture) {
            return 'assets/img/tours/t1.jpg'; // Default image
        }
        // Simple check to see if picture is a URL
        return picture.startsWith('http') ? picture : `${picture}`;
    };
    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error fetching cabs.</div>;
    }

    return (
        <section className="p-top-90 p-bottom-90 bg-gray-gradient">
            <div className="container">
                {/* <!-- Title --> */}
                <div className="mb-4" >
                    <div className="row g-0 g-md-2 align-items-end">
                        <div className="col-12 col-xl-4">
                            <div className="block-title me-auto">
                                <small className="sub-title">Book Cabs</small>
                                <h2 className="h1 title">Cabs in Tripnow</h2>
                            </div>
                        </div>

                    </div>
                </div>
                {/* <!-- /Title --> */}
                {/* <!-- Tours & Filters --> */}
                <div className="row g-0">
                    <div className="col-12 col-xl-9">
                        {/* <!-- Tour list --> */}
                        <div className="tour-list pe-xl-4 me-xl-2 mb-5 mb-xl-0">
                            {/* <!-- Tour --> */}
                            {
                                cabs.length > 0 && cabs.map((cab) => (
                                    <div className="tour-item rounded shadow-sm mb-4" key={cab._id}>
                                        <div className="row g-0">
                                            <div className="col-12 col-xl-4 col-md-6">
                                                <div className="tour-img">
                                                    <figure className="image-hover image-hover-overlay">
                                                        <img
                                                            src={cab.image && cab.image[0] ? getImageSrc(cab.image[0].imageAddress) : 'default-image.jpg'}
                                                            alt={cab.carName}
                                                            height={235}
                                                            style={{ objectFit: "cover" }}
                                                        />
                                                    </figure>
                                                </div>
                                            </div>
                                            <div className="col-12 col-xl-8 col-md-6 d-flex align-items-center">
                                                <div className="tour-content">
                                                    <h3 className="tour-title">
                                                        <Link to="./single-tour-1.html">{cab.carName}</Link>
                                                    </h3>
                                                    <div className="tour-info">
                                                        <div className="tour-itinerary">
                                                            <span><i className="hicon hicon-flights-pin"></i> {cab.carModel}</span>
                                                            <span><i className="hicon hicon-flights-pin"></i>AC</span>
                                                            <span><i className="hicon hicon-flights-pin"></i> {cab.seats} Seats</span>
                                                        </div>
                                                    </div>
                                                    <div className="inline-review review-border-0">
                                                        <span className="review-score">{cab.rating}</span>
                                                        <span className="star-rate-view star-rate-size-sm me-2">
                                                            <span className="star-value rate-50"></span>
                                                        </span>
                                                        <small className="review-total"><span>(231 reviews)</span></small>
                                                    </div>
                                                    <div className="tour-booking">
                                                        <Link to="/contact-us" className="btn btn-primary btn-uppercase w-35 mt-4">
                                                            Book now
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }

                            {/* <!-- /Tour --> */}


                            {/* <!-- Pagination --> */}
                            <nav aria-label="Page navigation" className="pt-3 text-center">
                                <ul className="pagination pagination-circle d-inline-flex mb-0">
                                    <li className="page-item">
                                        <Link className="page-link" to="#">
                                            <i className="hicon hicon-edge-arrow-left"></i>
                                        </Link>
                                    </li>
                                    <li className="page-item"><Link className="page-link" to="#">1</Link></li>
                                    <li className="page-item">
                                        <Link className="page-link" to="#">
                                            <i className="hicon hicon-edge-arrow-right"></i>
                                        </Link>
                                    </li>
                                </ul>
                            </nav>
                            {/* <!-- /Pagination --> */}
                        </div>
                        {/* <!-- /Tour list --> */}
                    </div>

                </div>
            </div>
        </section>)
}

export default CabsCards