import React, { useEffect, useRef } from 'react';
import Splide from '@splidejs/splide';
import '@splidejs/splide/dist/css/splide.min.css';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDestination } from '../redux/destinationSlice';

const Topdestination = () => {

    const dispatch = useDispatch();
    const { destinations, loading } = useSelector(state => state.destination);
    const splideInstance = useRef(null);

    const getImageSrc = (picture) => {
        if (!picture) {
            return '/assets/img/avatars/a3@2x.jpg'; // Default image
        }
        return picture.startsWith('http') ? picture : picture;
    };

    useEffect(() => {
        dispatch(fetchDestination());
    }, [dispatch]);

    useEffect(() => {
        // Only initialize Splide if products are loaded and the slider has not been initialized
        if (destinations.length && !splideInstance.current) {
            splideInstance.current = new Splide('#destination-slider', {
                type: 'loop',
                perPage: 4,
                perMove: 1,
                pagination: false,
                arrows: true,
                gap: '1rem',
                breakpoints: {
                    1200: {
                        perPage: 2,
                    },
                    768: {
                        perPage: 1,
                    },
                },
            }).mount();
        }

        // Cleanup function to destroy Splide instance on unmount
        return () => {
            if (splideInstance.current) {
                splideInstance.current.destroy();
                splideInstance.current = null; // Reset reference
            }
        };
    }, [destinations]);

    return (
        <section className="p-top-90 p-bottom-90 bg-gray-gradient">
            <div className="container">
                <div className="destination splide__pagination__start splide" id="destination-slider">
                    <div className="d-xl-flex align-items-xl-center mb-3">
                        <div className="block-title me-auto">
                            <small className="sub-title">Many tourists visit</small>
                            <h2 className="h1 title">Top destinations</h2>
                        </div>
                        <div className="d-lg-flex align-items-lg mt-3">
                            <div className="extra-info me-5">
                                <strong>+50</strong>
                                <span>Attractive destinations</span>
                            </div>
                            <div className="splide__arrows splide__arrows__right">
                                <button className="splide__arrow splide__arrow--prev me-2" style={{ backgroundColor: "rgb(81 67 217)", height: "40px", width: "40px", opacity: 1, borderRadius: "5px" }}>
                                    <i className="hicon hicon-edge-arrow-left"></i>
                                </button>
                                <button className="splide__arrow splide__arrow--next" style={{ backgroundColor: "rgb(81 67 217)", height: "40px", width: "40px", opacity: 1, borderRadius: "5px" }}>
                                    <i className="hicon hicon-edge-arrow-right"></i>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="splide__track pt-2 pb-2">
                        <ul className="splide__list">
                            {!loading && destinations.length > 0 ? destinations.map((destination) => {
                                const image1 = destination.image && destination.image[1] ? destination.image[1].imageAddress : null;
                                if (destination.products.length === 0) {
                                    return null; // Skip destinations with no products
                                }


                                return (
                                    <li className="splide__slide" key={`${destination.id}-${destination.name}`}>
                                        <Link to={`/destinations/${destination.slug.toLowerCase()}`} state={{ destination }} className="bottom-overlay hover-effect rounded">
                                            <figure className="image-hover image-hover-overlay mb-0">
                                                {image1 ? (
                                                    <img
                                                        src={getImageSrc(image1)}
                                                        alt={destination.name}
                                                        height={375}
                                                        width={300}
                                                    />
                                                ) : (
                                                    <img
                                                        src="/assets/img/hero/h1.jpg"
                                                        srcSet="/assets/img/hero/h1@2x.jpg 2x"
                                                        alt={destination.name}
                                                        height={375}
                                                        width={300}
                                                        style={{ objectFit: 'cover' }}
                                                    />
                                                )}
                                                <i className="hicon hicon-plus-thin image-hover-icon fs-5"></i>
                                            </figure>
                                            <div className="bottom-overlay-content">
                                                <div className="d-flex align-items-center">
                                                    <div className="d-flex flex-column me-auto">
                                                        <h3 className="mb-0">{destination.name}</h3>
                                                        <span>{destination.products.length} tours</span>
                                                    </div>
                                                    <span className="circle-icon circle-icon-link">
                                                        <i className="hicon hicon-flights-pin"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                );
                            }) : <p>No destinations available</p>}
                        </ul>
                    </div>
                </div>
                <p className="fw-normal text-secondary text-md-end mb-0 pt-4 mt-5 mt-md-0">
                    <Link to="/destinations" className="fw-medium">
                        <span>View all destinations</span>
                        <i className="hicon hicon-flights-one-ways"></i>
                    </Link>
                </p>
            </div>
        </section>
    );
};

export default Topdestination;
