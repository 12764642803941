import React from 'react'
import { Link } from 'react-router-dom'

const Whyus = () => {
    return (
        <section className="p-top-90 p-bottom-90 bg-gray-gradient" >
            <div className="container">
                <div className="row g-0">
                    <div className="col-12 col-xl-6 order-1 order-xl-0">
                        <div className="pe-xl-5">
                            <div className="image-info image-info-left image-info-right">
                                <div className="image-center">
                                    <img src="assets/img/about/tripnow-tours.avif" srcSet="assets/img/about/tripnow-tours.avif 2x" className="rounded w-100" alt="" />
                                </div>
                                <div className="info-top-right">
                                    <div className="vertical-review rounded shadow-sm">
                                        <div className="review-content">
                                            <h3 className="review-score">4.9</h3>
                                            <span className="star-rate-view star-rate-size-sm"><span className="star-value rate-50"></span></span>
                                            <small className="review-total"><strong>2394</strong> reviews</small>
                                            <small className="review-label ">Excellent</small>
                                        </div>
                                        <div className="review-footer">
                                            <small>Tripadvisor</small>
                                        </div>
                                    </div>
                                </div>
                                <div className="info-bottom-left">
                                    <div className="vertical-experience rounded shadow-sm">
                                        <h3 className="experience-year">+30</h3>
                                        <small className="experience-title">Years of experience</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-xl-6 order-0 order-xl-1">
                        <div className="mb-5">
                            <div className="block-title">
                                <small className="sub-title">Great experience</small>
                                <h2 className="h1 title">Why choose us</h2>
                            </div>
                            <p>
                                At TripNow, we redefine your travel experience with exceptional service, authentic local insights, and personalized itineraries. We’re committed to making every journey seamless, meaningful, and unforgettable.At TripNow, we redefine your travel experience with exceptional service, authentic local insights, and personalized itineraries. We’re committed to making every journey seamless, meaningful, and unforgettable.                            </p>
                            <div className="accordion accordion-flush accordion-why mb-4" id="acdWhy">
                                <div className="accordion-item bg-transparent">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#acdContent1" aria-expanded="false" aria-controls="acdContent1">
                                            <i className="hicon hicon-bold hicon-positive"></i>
                                            <span>Expertly Curated Tours</span>
                                        </button>
                                    </h2>
                                    <div id="acdContent1" className="accordion-collapse collapse" data-bs-parent="#acdWhy">
                                        <div className="accordion-body">
                                            Every trip is thoughtfully designed by our travel experts to ensure you experience the best of Varanasi, Prayagraj, Ayodhya, Bodhgaya, and Chitrakoot.                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item bg-transparent">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#acdContent2" aria-expanded="false" aria-controls="acdContent2">
                                            <i className="hicon hicon-bold hicon-positive"></i>
                                            <span>Personalized Travel Options</span>
                                        </button>
                                    </h2>
                                    <div id="acdContent2" className="accordion-collapse collapse" data-bs-parent="#acdWhy">
                                        <div className="accordion-body">
                                            From group tours to private journeys and tailor-made itineraries, we offer flexible travel plans that fit your unique preferences.                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item bg-transparent">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#acdContent3" aria-expanded="false" aria-controls="acdContent3">
                                            <i className="hicon hicon-bold hicon-positive"></i>
                                            <span>Reliable Support and Guidance</span>
                                        </button>
                                    </h2>
                                    <div id="acdContent3" className="accordion-collapse collapse" data-bs-parent="#acdWhy">
                                        <div className="accordion-body">
                                            Our team is with you every step of the way, ensuring your trip is smooth and hassle-free.                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item bg-transparent">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#acdContent4" aria-expanded="false" aria-controls="acdContent4">
                                            <i className="hicon hicon-bold hicon-positive"></i>
                                            <span>Affordable and Transparent Pricing</span>
                                        </button>
                                    </h2>
                                    <div id="acdContent4" className="accordion-collapse collapse" data-bs-parent="#acdWhy">
                                        <div className="accordion-body">
                                            We provide quality travel services at competitive prices, with no hidden costs—offering great value for your money.                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Link to="/tour-packages" className="btn btn-primary mnw-180">
                                <span>View tours</span>
                                <i className="hicon hicon-flights-one-ways"></i>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Whyus