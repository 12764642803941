import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';


const Hero = () => {
    const [packeges, setPackages] = useState("");
    const navigate = useNavigate();
    const data = packeges
    const searchHandler = () => {

        navigate(`/destinations/${data}`, { state: { data } })
    }
    return (
        <section className="hero">
            <h1 className="d-none">Tripnow</h1>
            {/* <!-- Carousel --> */}
            <div id="heroCarousel" className="hero-carousel carousel slide carousel-fade" data-bs-ride="carousel">
                <div className="carousel-inner">
                    {/* <!-- Carousel item --> */}
                    <div className="hero-item carousel-item active">
                        <div className="hero-bg">
                            <img src="./assets/img/hero/budhhaji-header-with-tripnow.avif" srcSet="./assets/img/hero/budhhaji-header-with tripnow.avif 2x" alt="" />
                        </div>
                        <div className="hero-caption text-sm-start">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 col-xxl-6 col-xl-7 col-md-10">
                                        <div className="hero-sub-title">
                                            <span>Explore Tripnow</span>
                                        </div>
                                        <h2 className="display-3 hero-title">
                                            Witness the Grandeur of the Sangam City
                                        </h2>
                                        <div className="hero-action">
                                            <Link to="/destinations" className="btn btn-outline-light btn-uppercase mnw-180 me-4">
                                                <span>Explore</span>
                                                <i className="hicon hicon-flights-one-ways"></i>
                                            </Link>
                                            <Link className="btn-video-play btn-video-play-sm media-glightbox" to="/assets/media/v1.mp4"><span></span></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- /Carousel item -->
                    <!-- Carousel item --> */}
                    <div className="hero-item carousel-item">
                        <div className="hero-bg">
                            <img src="./assets/img/hero/krishnaji-header-with-tripnow.avif" srcSet="./assets/img/hero/chhota-imambara-lucknow-tour-with-tripnow.jpg 2x" alt="" />
                        </div>
                        <div className="hero-caption text-sm-start">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 col-xxl-6 col-xl-7 col-md-10">
                                        <div className="hero-sub-title">
                                            <span>Explore TripNow</span>
                                        </div>
                                        <h2 className="display-3 hero-title">
                                            Embark on a Journey of Enlightenment in Bodhgaya
                                        </h2>
                                        <div className="hero-action">
                                            <Link to="/tour-packages" className="btn btn-outline-light btn-uppercase mnw-180 me-4">
                                                <span>Explore</span>
                                                <i className="hicon hicon-flights-one-ways"></i>
                                            </Link>
                                            <Link className="btn-video-play btn-video-play-sm media-glightbox" to="./assets/media/v1.mp4"><span></span></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- /Carousel item -->
                    <!-- Carousel item --> */}
                    <div className="hero-item carousel-item">
                        <div className="hero-bg">
                            <img src="./assets/img/hero/varanasi-header-with-tripnow.avif" srcSet="./assets/img/hero/varanasi-header-with tripnow.avif 2x" alt="" />
                        </div>
                        <div className="hero-caption text-sm-start">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 col-xxl-6 col-xl-7 col-md-10">
                                        <div className="hero-sub-title">
                                            <span>Explore Tripnow</span>
                                        </div>
                                        <h2 className="display-3 hero-title">
                                            Discover the Eternal Charm of Varanasi
                                        </h2>
                                        <div className="hero-action">
                                            <Link to="/tour-packages" className="btn btn-outline-light btn-uppercase mnw-180 me-4">
                                                <span>Explore</span>
                                                <i className="hicon hicon-flights-one-ways"></i>
                                            </Link>
                                            <Link className="btn-video-play btn-video-play-sm media-glightbox" to="./assets/media/v1.mp4"><span></span></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- /Carousel item --> */}
                </div>
                <div className="carousel-control">
                    <button className="carousel-control-next prev-custom" type="button" data-bs-target="#heroCarousel" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-prev next-custom" type="button" data-bs-target="#heroCarousel" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
                <div className="carousel-indicators hero-indicators-right">
                    <button type="button" data-bs-target="#heroCarousel" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#heroCarousel" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#heroCarousel" data-bs-slide-to="2" aria-label="Slide 3"></button>
                </div>
            </div>
            {/* <!-- Carousel -->
            <!-- Check tour --> */}
            <div className="container">
                <div className="row justify-content-end">
                    <div className="col-12 col-xl-6">
                        <div className="search-tours search-hero search-hero-half">
                            <form className="search-tour-form" onSubmit={(e) => { e.preventDefault() }}>
                                <div className="search-tour-input">
                                    <div className="row g-3 g-xl-2">
                                        <div className="col-12 col-md-6">
                                            <div className="input-icon-group">
                                                <label htmlFor="txtKeyword" className="input-icon hicon hicon-flights-pin"></label>
                                                <input id="txtKeyword" type="text" className="form-control shadow-sm" placeholder="Where are you going?" value={packeges} onChange={(e) => { setPackages(e.target.value) }} />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <button onClick={searchHandler} className="btn btn-primary btn-uppercase w-100">
                                                <i className="hicon hicon-bold hicon-search-box"></i>
                                                <span> Search</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- /Check tour --> */}
        </section>
    )
}

export default Hero