import React from 'react'
import { Link } from 'react-router-dom'

const SingleDestinationHero = ({ destination }) => {
    console.log(destination.image[0].imageAddress);

    const image1 = destination.image && destination.image[0] ? destination.image[0].imageAddress : null;
    console.log(image1);

    const getImageSrc = (picture) => {
        if (!picture) {
            return '/assets/img/avatars/a3@2x.jpg'; // Default image
        }
        return picture.startsWith('public/')
            ? picture.replace('public', '')
            : picture.startsWith('http')
                ? picture
                : `/${picture}`;
    };

    return (
        <section className="hero">
            <div className="hero-bg">
                {image1 ? (
                    <img
                        src={getImageSrc(image1)}
                        alt={destination.name}
                        height={488}
                        width={488}
                    />
                ) : (
                    <img
                        src="/assets/img/hero/h1.jpg"
                        srcSet="/assets/img/jero/h1@2x.jpg 2x"
                        alt={destination.name}
                        height={488}
                        width={488}
                    />
                )}
            </div>
            <div className="bg-content container">
                <div className="hero-page-title">
                    <span className="hero-sub-title">Hidden Charm</span>
                    <h1 className="display-3 hero-title">
                        Explore {destination.name || "Unknown Destination"}
                    </h1>
                </div>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb mb-0">
                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">{destination.name}</li>
                    </ol>
                </nav>
                <div className="title-info">
                    <div className="title-info-body">
                        <div className="row justify-content-end">
                            <div className="col-12 col-xxl-3 col-xl-4 col-md-6">
                                <div className="horizontal-review rounded-top ms-xl-2">
                                    <h2 className="h6 mb-2 ms-1">
                                        <i className="hicon hicon-bold hicon-flights-pin"></i>
                                        Attractive destination
                                    </h2>
                                    <div className="review-content">
                                        <h3 className="review-score">{destination.rating}</h3>
                                        <div className="review-total">
                                            <span className="star-rate-view">
                                                <span className="star-value rate-50"></span>
                                            </span>
                                            <span>
                                                <strong className="text-body">2,394</strong> reviews
                                            </span>
                                        </div>
                                    </div>
                                    <div className="review-label">
                                        <small>Excellent</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SingleDestinationHero
