import React, { useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'

import { useDispatch, useSelector } from 'react-redux';
import { fetchHotels } from '../redux/hotelSclice';


const SingleHotelPage = () => {
    const { id } = useParams()
    const dispatch = useDispatch();
    const { hotels, loading, error } = useSelector(state => state.hotels)
    useEffect(() => {
        dispatch(fetchHotels());
    }, [dispatch]);

    if (!loading && !error && hotels.length > 0) {

        const filteredHotels = hotels.find(
            (hotel) => id.toLowerCase().split("-").join("") === hotel.slug.toLowerCase().split(" ").join("")
        );
        // console.log(filteredHotels);


        return (
            <section className="p-top-90 p-bottom-90 bg-gray-gradient">

                <div className="container">

                    <div className="row g-0">
                        <div className='mb-5 border-bottom'>
                            <h2 className="display-3 hero-title">{filteredHotels.name}</h2>
                            <p style={{ color: "black" }}><span className="circle-icon circle-icon-link mx-2"><i className="hicon hicon-flights-pin"></i></span>
                                {filteredHotels.address}, {filteredHotels.city} - {filteredHotels.postCode}</p>
                        </div>
                        <div className="card border-0 shadow-sm p-xl-2 mb-4" >
                            <div className="card-body">
                                <div className="border-bottom mb-4 pb-4">
                                    <h2 className="text-body-emphasis mb-0">
                                        Photos
                                    </h2>
                                </div>
                                <div className="row g-1 align-items-center">
                                    <div className="col-12 col-xl-6">

                                        <figure className="image-hover image-hover-scale image-hover-overlay rounded mb-0">
                                            <img src="/assets/img/tours/t7.jpg" srcset="/assets/img/tours/t7@2x.jpg 2x" alt="" />
                                            <i className="hicon hicon-zoom-bold image-hover-icon fs-5"></i>
                                        </figure>

                                    </div>
                                    <div className="col-12 col-xl-6">
                                        <div className="row g-1 align-items-center">
                                            <div className="col-6">

                                                <figure className="image-hover image-hover-scale image-hover-overlay rounded mb-0">
                                                    <img src="/assets/img/tours/t8.jpg" srcset="/assets/img/tours/t8@2x.jpg 2x" alt="" />
                                                    <i className="hicon hicon-zoom-bold image-hover-icon fs-5"></i>
                                                </figure>
                                            </div>
                                            <div className="col-6">

                                                <figure className="image-hover image-hover-scale image-hover-overlay rounded mb-0">
                                                    <img src="/assets/img/tours/t9.jpg" srcset="/assets/img/tours/t9@2x.jpg 2x" alt="" />
                                                    <i className="hicon hicon-zoom-bold image-hover-icon fs-5"></i>
                                                </figure>
                                            </div>
                                            <div className="col-6">

                                                <figure className="image-hover image-hover-scale image-hover-overlay rounded mb-0">
                                                    <img src="/assets/img/tours/t10.jpg" srcset="/assets/img/tours/t10@2x.jpg 2x" alt="" />
                                                    <i className="hicon hicon-zoom-bold image-hover-icon fs-5"></i>
                                                </figure>
                                            </div>
                                            <div className="col-6">

                                                <figure className="image-hover image-hover-scale image-hover-overlay rounded mb-0">
                                                    <img src="/assets/img/tours/t11.jpg" srcset="/assets/img/tours/t11@2x.jpg 2x" alt="" />
                                                    <i className="hicon hicon-zoom-bold image-hover-icon fs-5"></i>
                                                </figure>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-xl-3">

                                        <figure className="image-hover image-hover-scale image-hover-overlay rounded mb-0">
                                            <img src="/assets/img/tours/t12.jpg" srcset="/assets/img/tours/t12@2x.jpg 2x" alt="" />
                                            <i className="hicon hicon-zoom-bold image-hover-icon fs-5"></i>
                                        </figure>

                                    </div>
                                    <div className="col-6 col-xl-3">

                                        <figure className="image-hover image-hover-scale image-hover-overlay rounded mb-0">
                                            <img src="/assets/img/tours/t13.jpg" srcset="/assets/img/tours/t13@2x.jpg 2x" alt="" />
                                            <i className="hicon hicon-zoom-bold image-hover-icon fs-5"></i>
                                        </figure>

                                    </div>
                                    <div className="col-12 col-xl-6">

                                        <figure className="image-hover image-hover-scale image-hover-overlay rounded mb-0">
                                            <img src="/assets/img/tours/t14.jpg" srcset="/assets/img/tours/t14@2x.jpg 2x" alt="" />
                                            <i className="hicon hicon-zoom-bold image-hover-icon fs-5"></i>
                                        </figure>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="pt-xl-4 mb-xl-0 mb-5">
                            <div className="block-title">
                                <small className="sub-title">{filteredHotels.name}</small>
                                <h2 className="h1 title lh-sm">{filteredHotels.about.title}</h2>
                            </div>
                            <p>
                                {filteredHotels.about.description}
                            </p>
                            <div className="pt-3 mb-5">
                                <Link to="/contact-us" className="btn btn-primary mnw-180">
                                    <i className="hicon hicon-email-envelope"></i>
                                    <span> Let's talk now</span>
                                </Link>
                                <a href={`tel:${filteredHotels.phoneNumber}`} className="btn btn-link link-dark link-hover fw-medium mnw-180">
                                    <i className="hicon hicon-telephone"></i>
                                    <span> {filteredHotels.phoneNumber}</span>
                                </a>
                            </div>
                        </div>
                        <div className="card border-0 shadow-sm p-xl-2 mb-4" >
                            <div className="card-body">
                                <div className="border-bottom mb-4 pb-4">
                                    <h2 className="text-body-emphasis mb-0">
                                        Policy
                                    </h2>
                                </div>
                                <div className="d-lg-flex border-bottom pb-3 mb-4">
                                    <div className="mnw-200">
                                        <h3 className="h5">Price included</h3>
                                    </div>
                                    <div>
                                        <ul className="highlight-list checked">
                                            {
                                                filteredHotels.policy.priceIncluded.map((data) => (
                                                    data.checked &&
                                                    <li>
                                                        <span><strong>{data.title}:</strong> {data.description}</span>
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                        <ul className="highlight-list unchecked">
                                            {
                                                filteredHotels.policy.priceIncluded.map((data) => (
                                                    !data.checked &&
                                                    <li>
                                                        <span><strong>{data.title}:</strong> {data.description}</span>
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                </div>
                                <div className="d-lg-flex border-bottom pb-3 mb-4">
                                    <div className="mnw-200">
                                        <h3 className="h5">For children</h3>
                                    </div>
                                    <ul className="highlight-list checked">
                                        {
                                            filteredHotels.policy.forChildren.map((data) => (
                                                data.checked &&
                                                <li>
                                                    <span><strong>{data.title}:</strong> {data.description}</span>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                    <ul className="highlight-list unchecked">
                                        {
                                            filteredHotels.policy.forChildren.map((data) => (
                                                !data.checked &&
                                                <li>
                                                    <span><strong>{data.title}:</strong> {data.description}</span>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </div>
                                <div className="d-lg-flex">
                                    <div className="mnw-200">
                                        <h3 className="h5">Other terms</h3>
                                    </div>
                                    <p>
                                        {filteredHotels.policy.OtherTerms}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )

    }
    else {
        return (
            <p>no data</p>
        )
    }

}

export default SingleHotelPage