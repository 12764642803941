import React, { useState } from 'react';
import axios from 'axios';
import UserProfileCard from '../components/UserProfileCard';
import { Link } from 'react-router-dom';

const Security = ({ loggedIn, adminLoggedIn }) => {
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [retypeNewPassword, setRetypeNewPassword] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const handlePasswordChange = async (e) => {
        e.preventDefault();

        if (newPassword !== retypeNewPassword) {
            setError("New password and retype password do not match!");
            return;
        }

        try {
            const response = await axios.post('/user/api/changepassword', {
                oldPassword,
                newPassword
            });

            if (response.data.success) {
                setSuccess("Password changed successfully!");
                setError('');
            } else {
                setError(response.data.message || "Failed to change password!");
            }
        } catch (error) {
            setError("Error changing password, please try again!");
        }
    };

    return (
        <main>
            <div className="p-top-90 p-bottom-90 bg-gray-gradient aos-init aos-animate">
                {/* <!-- Title --> */}
                <section className="container">
                    <div className="d-lg-flex align-items-lg-end pb-4">
                        <div className="block-title me-auto">
                            <span className="sub-title">My Account</span>
                            <h1 className="display-5 title">Security</h1>
                        </div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mt-3">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Security</li>
                            </ol>
                        </nav>
                    </div>
                </section>
                {/* <!-- /Title --> */}

                {/* <!-- Security --> */}
                <section className="container">
                    <div className="row g-0 g-xl-8">
                        <div className="col-12 col-xl-9">
                            {/* <!-- Change Password --> */}
                            <div className="pe-xl-4 me-xl-2">
                                <div className="card border-0 shadow-sm">
                                    <div className="card-body">
                                        <div className="d-flex align-items-center justify-content-between border-bottom pb-4 mb-4">
                                            <h2 className="h3 text-body-emphasis mb-0">Change Password</h2>
                                        </div>

                                        {error && <div className="alert alert-danger">{error}</div>}
                                        {success && <div className="alert alert-success">{success}</div>}

                                        <form onSubmit={handlePasswordChange}>
                                            <div className="row">
                                                <div className="col-12 col-md-6">
                                                    <div className="mb-4">
                                                        <label className="form-label" htmlFor="txtOldPassword">
                                                            Old Password <span className="text-danger">*</span>
                                                        </label>
                                                        <input
                                                            type="password"
                                                            className="form-control shadow-sm"
                                                            id="txtOldPassword"
                                                            value={oldPassword}
                                                            onChange={(e) => setOldPassword(e.target.value)}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 col-md-6">
                                                    <div className="mb-4">
                                                        <label className="form-label" htmlFor="txtNewPassword">
                                                            New Password <span className="text-danger">*</span>
                                                        </label>
                                                        <input
                                                            type="password"
                                                            className="form-control shadow-sm"
                                                            id="txtNewPassword"
                                                            value={newPassword}
                                                            onChange={(e) => setNewPassword(e.target.value)}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6">
                                                    <div className="mb-4">
                                                        <label className="form-label" htmlFor="txtRetypeNewPassword">
                                                            Retype New Password <span className="text-danger">*</span>
                                                        </label>
                                                        <input
                                                            type="password"
                                                            className="form-control shadow-sm"
                                                            id="txtRetypeNewPassword"
                                                            value={retypeNewPassword}
                                                            onChange={(e) => setRetypeNewPassword(e.target.value)}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="border-top pt-4">
                                                <button type="submit" className="btn btn-primary mnw-180">
                                                    <i className="hicon hicon-check-valid-state"></i>
                                                    Change Password
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- /Change Password --> */}
                        </div>
                        <div className="col-12 col-xl-3">
                            {/* <!-- Account menu --> */}
                            <div className="card border-0 shadow-sm sticky-top sticky-top-120 d-none d-xl-block z-0">
                                <UserProfileCard adminLoggedIn={adminLoggedIn} loggedIn={loggedIn} />
                            </div>
                            {/* <!-- Account menu --> */}
                        </div>
                    </div>
                </section>
                {/* <!-- /Security --> */}
            </div>
        </main>
    );
};

export default Security;
