import React from 'react';
import { Navigate } from 'react-router-dom';

const PrivateAdminRoute = ({ adminLoggedIn, children }) => {
    // console.log(adminLoggedIn);

    return adminLoggedIn ? children : <Navigate to="/admin" />;
};

export default PrivateAdminRoute;
