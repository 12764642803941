import React, { useState, useEffect } from 'react';
import UserProfileCard from '../components/UserProfileCard';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCabs, updateCab } from '../redux/cabSlice';




const UpdateCabs = ({ adminLoggedIn, loggedIn, setAdminLoggedIn, setLoggedIn }) => {
    const [errorMessage, setErrorMessage] = useState("");
    const [formData, setFormData] = useState({
        carName: '',
        rating: 0,
        seats: 4,
        carModel: '',
        images: [''], // Initial image field
    });
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { cabs, loading, error } = useSelector(state => state.cabs);
    const { id } = useParams(); // Get the ID from URL params
    const [cabID, setCabID] = useState(null);
    useEffect(() => {
        setCabID(id);
    }, [id]) // Set the cabID


    useEffect(() => {
        dispatch(fetchCabs());
    }, [dispatch]);

    useEffect(() => {
        if (cabs.length > 0 && !loading) {
            const cabData = cabs.find(cab => cab._id === id);
            if (cabData) {
                setFormData({
                    carName: cabData.carName || '',
                    rating: cabData.rating || 0,
                    seats: cabData.seats || 4,
                    carModel: cabData.carModel || '',
                    images: cabData.images || [''], // Set images as an array
                });
            }
        }
    }, [cabs, loading, id]);




    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({ ...formData, [name]: type === 'checkbox' ? checked : value });
    };


    const handleImageChange = (e) => {
        const files = Array.from(e.target.files);
        setFormData((prevFormData) => ({
            ...prevFormData,
            images: [...prevFormData.images, ...files],
        }));
    };





    const addImageField = () => {
        setFormData({ ...formData, images: [...formData.images, ''] });
    };
    const handleImageRemove = (index) => {
        const newImages = formData.images.filter((_, i) => i !== index);
        setFormData({ ...formData, images: newImages });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formDataToSend = new FormData();
        for (const key in formData) {
            if (Array.isArray(formData[key])) {
                if (key === 'images') {
                    // Ensure each item is a valid File before appending
                    formData[key].forEach((file) => {
                        if (file instanceof File) {
                            formDataToSend.append('images', file);
                        }
                    });
                } else {
                    formDataToSend.append(key, JSON.stringify(formData[key]));
                }
            } else {
                formDataToSend.append(key, formData[key]);
            }
        }
        for (let [key, value] of formDataToSend.entries()) {
            console.log(`${key}:`, value);
        }
        try {
            const response = await axios.put(`/admin/api/updatecabs/${cabID}`, formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            console.log('Server Response:', response.data);
            dispatch(updateCab(response.data))
            // Reset form state after successful submission
            setFormData({
                carName: '',
                rating: 0,
                seats: 0,
                carModel: '',
                images: [],
            });

            navigate('/addCabs');
        } catch (error) {
            console.error("There was an error adding the product:", error.response ? error.response.data : error.message);
            setErrorMessage("There was an error adding the product!");
        }
    };
    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error fetching cabs.</div>;
    }

    // Return early if no cab is found
    if (!formData.carName) {
        return <div>No cab found with the provided ID.</div>;
    }




    return (
        <main>
            <div className="p-top-90 p-bottom-90 bg-gray-gradient" >
                {/* <!-- Title --> */}
                <section className="container">
                    <div className="d-lg-flex align-items-lg-end pb-4">
                        <div className="block-title me-auto">
                            <small className="sub-title">My Account</small>
                            <h1 className="display-5 title">Cabs</h1>
                        </div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mt-3">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Add Hotels</li>
                            </ol>
                        </nav>
                    </div>
                </section>
                {/* <!-- /Title --> */}

                {/* <!-- Settings --> */}
                <section className="container">
                    <div className="row g-0 g-xl-8">
                        <div className="col-12 col-xl-9">
                            {/* <!-- Your Profile --> */}
                            <div className="pe-xl-4 me-xl-2">
                                <div className="card border-0 shadow-sm">
                                    <div className="card-body">
                                        <div className="d-flex align-items-center justify-content-between border-bottom pb-4 mb-4">
                                            <h2 className="h3 text-body-emphasis mb-0">Add Cabs</h2>
                                        </div>
                                        {errorMessage && (
                                            <div className="alert alert-danger">
                                                {errorMessage}
                                            </div>
                                        )}
                                        <form className="form-container" onSubmit={handleSubmit}>
                                            <div className="form-group">
                                                <label>Car Name:</label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    name="carName"
                                                    value={formData.carName}
                                                    onChange={handleChange}
                                                />
                                            </div>


                                            <div className="form-group">
                                                <label>Rating:</label>
                                                <input
                                                    className="form-control"
                                                    type="number"
                                                    name="rating"
                                                    value={formData.rating}
                                                    onChange={handleChange}
                                                    min={1}
                                                    max={5}
                                                    step={0.1}
                                                />
                                            </div>

                                            <div className="form-group">
                                                <label>Car Model:</label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    name="carModel"
                                                    value={formData.carModel}
                                                    onChange={handleChange}
                                                />
                                            </div>

                                            <div className="form-group">
                                                <label>Seats:</label>
                                                <input
                                                    className="form-control"
                                                    type="number"
                                                    name="seats"
                                                    value={formData.seats}
                                                    onChange={handleChange}
                                                    min={4}
                                                    max={10}
                                                />
                                            </div>


                                            <div className="form-group">
                                                <label>Images:</label>
                                                <input
                                                    type="file"
                                                    className="form-control"
                                                    accept="image/*"
                                                    multiple
                                                    onChange={handleImageChange}
                                                    name="images"
                                                />
                                                <button type="button" className="btn btn-secondary mt-2" onClick={addImageField}>
                                                    Add More Images
                                                </button>
                                                {formData.images.map((image, index) => (
                                                    <div key={index}>
                                                        <span>{image.imageAddress || 'Image selected'}</span>
                                                        <button type="button" onClick={() => handleImageRemove(index)}>Remove</button>
                                                    </div>
                                                ))}
                                            </div>




                                            <button type="submit" className="btn btn-primary">Submit</button>
                                        </form>

                                    </div>
                                </div>
                            </div>
                            {/* <!-- /Your Profile --> */}
                        </div>
                        <div className="col-12 col-xl-3">
                            {/* <!-- Account menu --> */}
                            <div className="card border-0 shadow-sm sticky-top sticky-top-120 d-none d-xl-block z-0">
                                <UserProfileCard adminLoggedIn={adminLoggedIn} loggedIn={loggedIn} setAdminLoggedIn={setAdminLoggedIn} setLoggedIn={setLoggedIn} />
                            </div>
                            {/* <!-- Account menu --> */}
                        </div>
                    </div>
                </section>
                {/* <!-- /Settings --> */}
            </div>
        </main>
    );
};

export default UpdateCabs;
