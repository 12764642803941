import React, { useEffect, useRef } from 'react';
import Splide from '@splidejs/splide';
import '@splidejs/splide/dist/css/splide.min.css';

const AboutWhyChooseUs = () => {
    const sliderRef = useRef(null);

    useEffect(() => {
        const splide = new Splide(sliderRef.current, {
            perPage: 4,
            gap: '1.5rem',
            padding: 0,
            speed: 1000,
            type: 'loop',
            focus: 0,
            arrows: true, // Enable arrows
            pagination: false,
            breakpoints: {
                1200: {
                    perPage: 1,
                    padding: { left: 0, right: '280px' },
                },
                992: {
                    perPage: 1,
                    padding: { left: 0, right: '250px' },
                },
                576: {
                    perPage: 1,
                    gap: '0.75rem',
                    padding: 0,
                },
            },
        });

        splide.on('mounted', () => {
            sliderRef.current.style.visibility = 'visible'; // Set visibility to visible on mount
        });

        splide.mount();

        // Cleanup on unmount
        return () => {
            splide.destroy();
        };
    }, []);

    return (
        <section className="hero">
            <div className="hero-bg">
                <img src="./assets/img/background/varanasi-tripnow.avif" srcSet="./assets/img/background/varanasi-tripnow.avif 2x" alt="" />
            </div>
            <div className="bg-content container">
                <div className="p-top-90 p-bottom-50">
                    <div className="block-title text-center pb-4">
                        <small className="sub-title text-white">Great experience</small>
                        <h2 className="h1 title text-white">Why choose us</h2>
                        <p className='text-white'>We know travel isn’t just about going from one place to another – it’s about the moments, the stories, and the connections. That’s why at TripNow, we go the extra mile to make sure your trip is personal, memorable, and stress-free.</p>
                    </div>
                    <div ref={sliderRef} id="testimonial-slider" className="why-slider splide w-100 mb-5 mb-xl-0" style={{ visibility: 'hidden' }}>
                        <div className="splide__track pt-2 pb-2">
                            <ul className="splide__list">
                                <li className="splide__slide">
                                    <div className="transparent-card rounded hover-effect">
                                        <div className="card-icon">
                                            <i className="hicon hicon-family-with-teens"></i>
                                        </div>
                                        <h3 className="h5 card-title">Local Experts with a Personal Touch</h3>
                                        <p className="card-desc">
                                            Our guides are locals who don’t just show you the sights; they share the stories, traditions, and secrets that make these places special.
                                        </p>
                                    </div>
                                </li>
                                <li className="splide__slide">
                                    <div className="transparent-card rounded hover-effect">
                                        <div className="card-icon">
                                            <i className="hicon hicon-regular-travel-protection"></i>
                                        </div>
                                        <h3 className="h5 card-title">Tailored Just for You</h3>
                                        <p className="card-desc">
                                            Every traveler is unique, and so are our tour packages. Whether you're on a spiritual quest or seeking cultural experiences, we’ll design your trip to match your interests.</p>
                                    </div>
                                </li>
                                <li className="splide__slide">
                                    <div className="transparent-card rounded hover-effect">
                                        <div className="card-icon">
                                            <i className="hicon hicon-tours"></i>
                                        </div>
                                        <h3 className="h5 card-title">Effortless Travel, Every Time</h3>
                                        <p className="card-desc">
                                            We take care of the nitty-gritty details – transport, stays, and everything in between – so you can focus on soaking in the experience.
                                        </p>
                                    </div>
                                </li>
                                <li className="splide__slide">
                                    <div className="transparent-card rounded hover-effect">
                                        <div className="card-icon">
                                            <i className="hicon hicon-tours"></i>
                                        </div>
                                        <h3 className="h5 card-title">Affordable Adventures</h3>
                                        <p className="card-desc">
                                            We believe unforgettable journeys shouldn’t come at a steep price. At TripNow, we combine comfort, quality, and affordability.
                                        </p>
                                    </div>
                                </li>
                                <li className="splide__slide">
                                    <div className="transparent-card rounded hover-effect">
                                        <div className="card-icon">
                                            <i className="hicon hicon-tours"></i>
                                        </div>
                                        <h3 className="h5 card-title">Sustainable & Responsible</h3>
                                        <p className="card-desc">
                                            Travel should leave footprints on hearts, not the environment. We’re committed to eco-friendly practices that respect local communities and nature.
                                        </p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        {/* Custom Arrow Buttons */}
                        <div className="splide__arrows splide__arrows__right" style={{ position: "absolute", top: "-20px" }}>
                            <button className="splide__arrow splide__arrow--prev me-2" style={{ backgroundColor: '#5143d9', height: '40px', width: '40px', opacity: 1, borderRadius: '5px' }}>
                                <i className="hicon hicon-edge-arrow-left"></i>
                            </button>
                            <button className="splide__arrow splide__arrow--next" style={{ backgroundColor: '#5143d9', height: '40px', width: '40px', opacity: 1, borderRadius: '5px' }}>
                                <i className="hicon hicon-edge-arrow-right"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AboutWhyChooseUs;
